import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../../screen/dashboard";
import NoPageFound from "../../screen/NoPageFound";
import ProfilePage from "../../screen/profile";
import AllClientsList from "../../screen/WafAdmin/Client/ClientList";
import Users from "../../screen/WafAdmin/User/Users";
import Support from "../../screen/support";
import Logout from "../../screen/Logout";
import CONSTANTS from "../../constants/constants";

export const Navigator = () => {
  
  return (
    <Routes>
      <Route path={`${CONSTANTS.NAVIGATE_TO_DASHBOARD}`} element={<Dashboard/>} />

      <Route path={`${CONSTANTS.NAVIGATE_TO_DASHBOARD}user-profile`} element={<ProfilePage/>} />
      <Route path={`${CONSTANTS.NAVIGATE_TO_DASHBOARD}support`} element={<Support />} />
      <Route path={`${CONSTANTS.NAVIGATE_TO_DASHBOARD}users`} element={<Users />} />

      <Route path={`${CONSTANTS.NAVIGATE_TO_DASHBOARD}clients`} element={<AllClientsList />} />
      <Route path={`${CONSTANTS.NAVIGATE_TO_DASHBOARD}${process.env.REACT_APP_REALM}`} element={<Logout/>} />
      <Route path={`${CONSTANTS.NAVIGATE_TO_DASHBOARD}*`} element={<NoPageFound />} />


    </Routes>
  );
};

export default Navigator;
