import React, { useState, useEffect } from 'react';
import { MenuItem, Select, FormControl, Popover, Box, Button, Radio, RadioGroup, FormControlLabel, IconButton, TextField, InputAdornment, Divider } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as CalendarTodayIcon } from '../../../assets/icons/svg/calender.svg';
import { ReactComponent as DownArrow } from '../../../assets/icons/svg/downArrow.svg';

import './customDate.css';
import { useDispatch, useSelector } from 'react-redux';
import { setDurationData, setStartAndEndDateTime } from '../../../redux/ui/uiActions';
import { convertAndFormatDate, convertToISTFormat, dateSlashFormat, getDatePart, getDateTimeForLabel } from '../../../screen/utils/convertTime';
import CONSTANTS from '../../../constants/constants';
import { pushNotification } from '../../../redux/notification/notificationActions';
import { durations } from '../../initial/initialButtons';

const useStyles = makeStyles((theme) =>({
  menuItem: {
    fontSize:'0.8rem !important',
    [theme.breakpoints.up('xs')]: {
      fontSize:'0.9rem !important',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize:'0.95rem !important',
    },
    [theme.breakpoints.up('md')]: {
      fontSize:'0.9rem !important',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize:'1rem !important',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize:'1.2rem !important',
    },
    '@media (min-width:2560px)': {
      fontSize: '1.5rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.8rem !important',
    },
    borderBottom: '1px solid #e0e0e0 !important', // Add bottom border
    '&:last-child': {
      borderBottom: 'none !important', // Remove bottom border for the last item
    },
  },
  durationSelect: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0.1rem solid #0046FF !important',
      borderRadius: '0.5rem',
  
    },
    '& .MuiSelect-icon': {
      display: 'none',
    },
    '& .MuiSelect-select': {
      display:'flex',
      color: '#0046FF', // Add red color to select items
      fontSize:'0.9rem !important',
      [theme.breakpoints.up('xs')]: {
        fontSize:'0.9rem !important',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize:'0.95rem !important',
      },
      [theme.breakpoints.up('md')]: {
        fontSize:'0.9rem !important',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize:'1rem !important',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize:'1.2rem !important',
      },
      '@media (min-width:2560px)': {
        fontSize: '1.5rem !important',
      },
      '@media (min-width:3840px)': {
        fontSize: '1.8rem !important',
      },
      
    },

  },
  select: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiSelect-icon': {
      display: 'none',
    },
   
  },

  selectWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  selectRoot: {
    flexGrow: 1,
  },
  downArrow: {
    position: 'absolute',
    right: 1,
    pointerEvents: 'none', // Ensure the icon does not block interactions with the select component
  },

}));

const DurationPicker = ({setPage}) => {
  const classes = useStyles();

  const [selectedDuration, setSelectedDuration] = useState(
    sessionStorage.getItem(CONSTANTS.TIME_DURATION) || durations[0].value);

  const [isCustomDate, setIsCustomDate] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { isRefresh } = useSelector((state: any) => state.loaders);
  const [storedCustomDate, setStoredCustomDate] = useState<string>(
    (sessionStorage.getItem(CONSTANTS.CUSTOM_LABEL)) || ""
  );

  const [isFromTo, setIsFromTo] = useState(
    sessionStorage.getItem(CONSTANTS.IS_FROM_TO)?sessionStorage.getItem(CONSTANTS.IS_FROM_TO)==='fromTo':true
  );
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());  
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [nextMonth, setNextMonth] = useState(new Date(new Date().setMonth(new Date().getMonth() + 1)));
  const [anchorEl, setAnchorEl] = useState(null);
  const [lastClickedDate, setLastClickedDate] = useState<any>(null);

  const dispatch = useDispatch()

  let stored:any = sessionStorage.getItem(CONSTANTS.CUSTOM_LABEL) 


  useEffect(() => {
    if (!isFromTo) {
      setEndDate(startDate);
    }

  }, [isFromTo, startDate]);

  useEffect(()=>{

    const changeInDuration = () =>{
      const startAndEndDate = getDateTimeForLabel(selectedDuration?.split('-')[0])
      dispatch(setStartAndEndDateTime({...startAndEndDate}))
  
     }
    if(!selectedDuration.includes('custom')){
      changeInDuration()
    }

    setStoredCustomDate(stored)
    
   },[selectedDuration, dispatch, isRefresh, stored])

   


  const renderCalendar = (month, isNextMonth) => {
    const daysInMonth = new Date(month.getFullYear(), month.getMonth() + 1, 0).getDate();
    const firstDayOfMonth = new Date(month.getFullYear(), month.getMonth(), 1).getDay();
    const days:any[] = [];
  
    // Add previous month's days
    const prevMonthDays = new Date(month.getFullYear(), month.getMonth(), 0).getDate();
    for (let i = firstDayOfMonth - 1; i >= 0; i--) {
      days.push(<div key={`prev-${prevMonthDays - i}`} className="day other-month">{prevMonthDays - i}</div>);
    }
  
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    // Current month's days
    for (let i = 1; i <= daysInMonth; i++) {
      const currentDate = new Date(month.getFullYear(), month.getMonth(), i);
      currentDate.setHours(0, 0, 0, 0);
  
      const isSelected = startDate && endDate && currentDate >= startDate && currentDate <= endDate;
      const isToday = currentDate?.getTime() === today?.getTime();
      const isStart = startDate && currentDate?.getTime() === startDate?.getTime();
      const isEnd = endDate && currentDate?.getTime() === endDate?.getTime();
      const isFuture = currentDate > today; // Check if the date is in the future
  
      let className = "day";
  
      if (isSelected || isStart || isEnd) {
        className += " selected";
        if (isStart) className += " start";
        if (isEnd) className += " end";
        if (isSelected && !isStart && !isEnd) className += " middle";
      }
  
      if (isToday) className += " today";
      if (isFuture) className += " disabled"; // Add class for future dates
  
      days.push(
        <div
          key={i}
          className={className}
          onClick={!isFuture ? () => handleDateClick(currentDate) : undefined} // Disable click for future dates
        >
          {i}
        </div>
      );
    }
  
    // Add next month's days
    const totalDays = days.length;
    for (let i = 1; totalDays + i <= 42; i++) {
      days.push(<div key={`next-${i}`} className="day other-month">{i}</div>);
    }
  
    return (
      <div className="calendar">
        <div className="calendar-header">
          <div className="month-year-selector">
            <Select
              value={month.getMonth()}
              onChange={(e) => handleMonthChange(e, isNextMonth)}
              className={classes.select}
            >
              {months.map((monthName, index) => (
                <MenuItem key={monthName} value={index}>
                  {monthName}
                </MenuItem>
              ))}
            </Select>
            <Select
              value={month.getFullYear()}
              onChange={(e) => handleYearChange(e, isNextMonth)}
              className={classes.select}
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div className="weekdays">
          <div>S</div><div>M</div><div>T</div><div>W</div><div>T</div><div>F</div><div>S</div>
        </div>
        <div className="days">{days}</div>
      </div>
    );
  };
    
  const handleDoneClick = () => {
    let startDateTime = new Date(startDate);
    let endDateTime = new Date(endDate);    

  
    startDateTime.setHours(0, 0, 0);
    endDateTime.setHours(23, 59, 59);

    const startDateStr  = convertToISTFormat(startDateTime)
    const endDateStr = convertToISTFormat(endDateTime)

    sessionStorage.setItem(CONSTANTS.CUSTOM_LABEL,JSON.stringify({
      from:startDateStr,
      to:endDateStr
    }))

    if(startDateStr === endDateStr){
      dispatch(
        pushNotification({
          isOpen: true,
          message: 'Please select the end date also',
          type: CONSTANTS.ERROR,
        })
      )
    }else{
      dispatch(setStartAndEndDateTime({
        from:startDateStr,
        to:endDateStr
      }))


      setAnchorEl(null)
      setIsCustomDate(false)

    }

  };

  const handleDateClick = (date) => {

    if (!isFromTo) {
      // Single day selection
      setStartDate(date);
      setEndDate(date);
    } else {
      const now:any = new Date();
  
      if (lastClickedDate && (now - lastClickedDate < 300)) { // Check for double click
        // Double-click detected
        setStartDate(date);
        setEndDate(date);
        setLastClickedDate(null); // Reset lastClickedDate after setting start date
      } else {
        // Single-click behavior
        if (!startDate || date < startDate) {
          // If no start date is set or selected date is before current start date
          setStartDate(date);
          setEndDate(date);
          setLastClickedDate(new Date()); // Update lastClickedDate on single click
        } else if (date > startDate) {
          // If selected date is after start date
          setEndDate(date);
          setLastClickedDate(new Date()); // Update lastClickedDate on single click
        } else {
          // If selected date is same as start date
          if (date?.getTime() === endDate?.getTime()) {
            // If clicking on the same date as both start and end, reset end date
              return 
          } else {
            // Otherwise, set it as new end date
            setEndDate(date);
          }
          setLastClickedDate(new Date()); // Update lastClickedDate on single click
        }
      }
    }
  };
  

  const handlePrevMonth = () => {
    setCurrentMonth(new Date(currentMonth.setMonth(currentMonth.getMonth() - 1)));
    setNextMonth(new Date(nextMonth.setMonth(nextMonth.getMonth() - 1)));
  };

  const handleNextMonth = () => {
    setCurrentMonth(new Date(currentMonth.setMonth(currentMonth.getMonth() + 1)));
    setNextMonth(new Date(nextMonth.setMonth(nextMonth.getMonth() + 1)));
  };

  const handleOpenCustomSelectedDate = (e) => {
    setAnchorEl(null);
    setIsCustomDate(true);
    
  };

  const handleOpenCustomDate = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setIsCustomDate(true);
  };

  const handleTimeDurationChange = (e) =>{

    const parsedValue = JSON.parse(e.target.value)

    setSelectedDuration(parsedValue?.value);

    sessionStorage.setItem(CONSTANTS.TIME_DURATION,parsedValue?.value)

    if (parsedValue?.label=== 'Custom Duration') {
      handleOpenCustomDate(e);
    }

    dispatch(setDurationData(e?.target?.value))
    setPage(1)
    
   
  }

  const handleCloseCustomDate = () => {
    setAnchorEl(null);
    setIsCustomDate(false);
  };

  const handleMonthChange = (event, isNextMonth) => {
    const newMonth = new Date(isNextMonth ? nextMonth : currentMonth);
    newMonth.setMonth(event.target.value);
    if (isNextMonth) {
      setNextMonth(newMonth);
    } else {
      setCurrentMonth(newMonth);
      setNextMonth(new Date(new Date(newMonth).setMonth(newMonth.getMonth() + 1)));
    }
  };

  const handleYearChange = (event, isNextMonth) => {
    const newMonth = new Date(isNextMonth ? nextMonth : currentMonth);
    newMonth.setFullYear(event.target.value);
    if (isNextMonth) {
      setNextMonth(newMonth);
    } else {
      setCurrentMonth(newMonth);
      setNextMonth(new Date(new Date(newMonth).setMonth(newMonth.getMonth() + 1)));
    }
  };

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() + i - 5);
 
  return (
    <div className="custom-duration-select">
    <FormControl variant="outlined" fullWidth>
    <div className={classes.selectWrapper}>
      <Select
        className={`duration-select-component ${classes.selectRoot} ${classes.durationSelect}`}
        labelId="duration-select-label"
        value={selectedDuration}
        onChange={handleTimeDurationChange}
        label=""
        renderValue={(selected) => {
          const selectedOption = durations?.find(option => option?.value === selected);
          return selectedOption ? selectedOption?.label : '';
        }}
        onOpen={() => setIsDropdownOpen(true)}
        onClose={() => setIsDropdownOpen(false)}
      >
        {durations?.map((option) => (
        <MenuItem className={classes.menuItem} key={option?.label} value={JSON.stringify(option)}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Radio
                checked={selectedDuration === option?.value}
                sx={{color:"#0046FF"}} />
              {option?.label}
            </div>
            {option?.label === 'Custom Duration' && selectedDuration.includes('custom') && (
              <div 
                className="custom-date-range" 
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenCustomSelectedDate(e);
                }}
              >
                <Box display={'flex'} justifyContent={"space-between"} alignItems={"center"}>
                  <CalendarTodayIcon />
                  <Box width={"100%"} display={'flex'} flexDirection={"column"} alignItems={"center"}>
                    {isFromTo  ? (
                      <>
                        {/* Ensure `sessionStorage` contains valid JSON strings for dates */}
                        {sessionStorage.getItem(CONSTANTS.CUSTOM_LABEL) ? (
                          <>
                            <span className='selected-date'>{getDatePart(JSON.parse(sessionStorage?.getItem(CONSTANTS.CUSTOM_LABEL) as any)?.from || convertAndFormatDate(startDate))}</span>
                            <span className='selected-date'>to</span>
                            <span className='selected-date'>{getDatePart(JSON.parse(sessionStorage?.getItem(CONSTANTS.CUSTOM_LABEL) as any)?.to || convertAndFormatDate(endDate))}</span>
                          </>
                        ) : (
                          <>
                            {storedCustomDate ? 
                              <span className='selected-date'>{getDatePart(JSON.parse(sessionStorage?.getItem(CONSTANTS.CUSTOM_LABEL) as any)?.from || convertAndFormatDate(startDate))}</span>:
                              convertAndFormatDate(startDate)
                            }
                           
                          </>
                        )}
                      </>
                    ) : (
                      <span>
                        {storedCustomDate ? 
                          <span className='selected-date'>{getDatePart(JSON.parse(sessionStorage?.getItem(CONSTANTS.CUSTOM_LABEL) as any)?.from || convertAndFormatDate(startDate))}</span>:
                          convertAndFormatDate(startDate)
                        }
                      </span>
                    )}
                  </Box>

                </Box>
              </div>
            )}
            
          </div>
        </MenuItem>
      ))}
 
      </Select>
      <DownArrow   
      style={{ transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)' }} 
      className={classes.downArrow} />
    </div>
      </FormControl>
      <Popover
        className='date-picker-modal'
        
        open={isCustomDate}
        anchorEl={anchorEl}
        onClose={handleCloseCustomDate}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box  sx={{height:{xs:400,xl:550}}} className="date-picker-popover">
          <div className="date-picker-content">
            <div className="date-picker-header">
              <FormControl className="radio-group-and-date-container">
                <RadioGroup
                  row
                  value={isFromTo ? 'fromTo' : 'today'}
                  onChange={(e) => {
                    setIsFromTo(e.target.value === 'fromTo');
                    sessionStorage.setItem(CONSTANTS.IS_FROM_TO,e.target.value)
                    if (e.target.value === 'today') {
                      const today = new Date();
                      setStartDate(today);
                      setEndDate(today);
                    }
                  }}
                  className="date-type-selector"
                >
                  <FormControlLabel className='custom-form-control-label' value="fromTo" control={<Radio/>} label="From-To" />
                  <FormControlLabel className='custom-form-control-label' value="today" control={<Radio />} label="Single Day" />
                </RadioGroup>
              </FormControl>
              <div className='date-btn-container'>
                <TextField
                  sx={{ mr: 1 }}
                  size="small"
                  value={isFromTo ? `${dateSlashFormat(startDate)} - ${dateSlashFormat(endDate)}` :dateSlashFormat(startDate)}
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton onClick={handleCloseCustomDate}>
                          <CalendarTodayIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button className="done-btn" variant="contained" onClick={handleDoneClick}>
                  Done
                </Button>
              </div>
            </div>
            <Divider sx={{marginLeft:'-1.2rem',marginRight:'-1.2rem'}}/>
            <div className="calendar-container">
              <div className="calendars">
                <IconButton className="nav-button prev" onClick={handlePrevMonth}>
                  <ArrowBackIosNewIcon />
                </IconButton>
                {renderCalendar(currentMonth, false)}
                {renderCalendar(nextMonth, true)}
                <IconButton className="nav-button next" onClick={handleNextMonth}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </div>
            </div>
           
          </div>
        </Box>
      </Popover>
    </div>
  );
};

export default DurationPicker;

