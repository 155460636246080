import React, { useState } from 'react';
import { Chip, IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import CONSTANTS from '../../../constants/constants';
import { deleteUrlFromClient } from './clientServices';
import { pushNotification } from '../../../redux/notification/notificationActions';
import EditDomain from './EditDomain';
import DeleteConfirmationDialog from '../../../components/common/customDeleteBox/DeleteConfirmationDialog';

interface Props {
  domain: string;
  domainId: string;
  clientId?:any;
  domainInfo?:any;
  handleGetAllurls?: () => void;
  modeValue?: string;
  selectMode?: boolean;
  setSelectMode?: (mode: boolean) => void;
  handleGetPaginatedClients?: () => void;
}

const EditableChip: React.FC<Props> = ({
  domain,
  domainId,
  clientId,
  domainInfo,
  handleGetAllurls,
  modeValue,
  selectMode,
  setSelectMode,
  handleGetPaginatedClients
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDomainDelete,setIsDomainDelete] = useState<boolean>(false)
  const dispatch = useDispatch();

  // console.log("clientid in edit chip ",clientId)
  const handleEditClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsEditing(true);
  };

  const handleCloseEdit = () => {
    setIsEditing(false);
    setAnchorEl(null);
  };

  const handleDeleteClick = async () => {
    // console.log("delte clicked")
    const response: any = await deleteUrlFromClient(domainId);

    if (response?.success) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.SUCCESS,
        })
      );
      if (handleGetPaginatedClients) {
        handleGetPaginatedClients();
      }
    } else {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.error,
          type: CONSTANTS.ERROR,
        })
      );
    }
  };

  return (
    <div>
      <Chip
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          mr: "2rem",
          p: { xs: "0.7rem", lg: "0.9rem", xl: "1.1rem" },
          backgroundColor: "lightblue",
          '& .MuiChip-label': {
            fontSize: { xs: "0.7rem", lg: "0.9rem", xl: "1.1rem" }
          }
        }}
        label={domain}
        variant="outlined"
        onDelete={handleDeleteClick}
        deleteIcon={
          <>
            <IconButton onClick={handleEditClick} size="small">
              <Edit sx={{ color: "blue", fontSize: "1.2rem" }} />
            </IconButton>
            <IconButton onClick={()=>setIsDomainDelete(true)}>
              <Delete sx={{cursor:"pointer", color: "gray", fontSize: "1.4rem" }} />
            </IconButton>
          </>
        }
      />
       {isDomainDelete &&
          <DeleteConfirmationDialog
              title = {'Delete domain'}
              description={'Are you sure you want to delete this domain?'}
              open={isDomainDelete}
              onClose={() => setIsDomainDelete(false)}
              onConfirm={handleDeleteClick}
          />}
      {isEditing && (
        // <EditForm/>
        <EditDomain
          clientId={clientId}
          domainInfo={domainInfo}
          handleGetPaginatedClients={handleGetPaginatedClients}
          isEditing={isEditing}
          onClose={handleCloseEdit}
          anchorEl={anchorEl}
        />
      )}
    </div>
  );
};

export default EditableChip;