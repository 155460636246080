import { makeStyles, Typography } from '@material-ui/core';
import { Box } from '@mui/material';
import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Sector, Tooltip } from 'recharts';
import { processData } from '../../utils/processData';
import NoAttacksFound from '../../NoAttacksFound';
import useResponsiveDimensions from '../../../components/common/useResponsiveDimensions';

const COLORS = ['#FF0000', '#FFA500', '#008000', '#9E9EA8'];
const BACKGROUND_COLOR = '#E3F2FD'; // Light blue color

const useStyles = makeStyles((theme) => ({
  tooltip: {
    
    position: 'absolute', 
    background: '#4A5568',
    boxShadow: '0px 1px 4px 0px #2C405A35',
    color: '#FFFFFF',
    border: '1px solid #ccc',
    padding: '10px',
    pointerEvents: 'none',
    zIndex: 1000,
    borderRadius: '4px',
    maxWidth: '15rem', // Add max-width to prevent oversizing
    wordWrap: 'break-word', // Ensure long words don't 
  },
  arrow: {
    width: 0,
    height: 0,
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderTop: '6px solid #4A5568',
    position: 'absolute',
    left: '50%',
    top:'100%',
    transform: 'translateX(-50%)',
    filter: 'drop-shadow(0 1px 0 #FFFFFF)', // Add grey border effect

  },
  chartTitle: {
    textAlign: 'left',
    marginBottom: theme.spacing(2),
    color: '#333333',
    fontSize: '1.2rem',
    fontWeight: 'bold'
  },
  legendItem: {
    fontSize: '0.85rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.75rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.8rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '0.85rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.2rem',
    },
   
  },

}));

export const CustomTooltip = ({ coordinate,active, payload }) => {

  const classes = useStyles()


  if (active && payload && payload.length) {
    const data = payload[0].payload;
    const {x,y} = coordinate
    return (
      <div
        className={classes.tooltip}
        style={{
          left: x,top: y,
          transform: 'translate(-60%, -80%)',
          backgroundColor:data?.fill
        }}
      >
        {data?.name !== 'Others' ? (
          <Typography variant="body2" style={{ width:'max-content',fontWeight: 'bold' }}>
            {data?.name} - {data?.value}
          </Typography>
        ) : (
          data?.breakdown?.map((item, index) => {
            return (
              <div style={{maxHeight:200,overflowY:'auto'}} key={`${item?.name}-${index}`}>
                <Typography variant="body2" style={{ width:'max-content',fontWeight: 'normal' }}>
                  {item?.name} - {item.value}
                </Typography>
              </div>
            );
          })
        )}
      <div className={classes.arrow} style={{ borderTopColor: data?.fill }}></div>

      </div>
    );
  }

  return null;
};

export const renderLegend = (props) => {
  const { payload,classes } = props;
  return (
    <Box sx={{padding: 0}}>
      <ul style={{listStyle: 'none', padding: 0, display: 'flex', justifyContent: 'center',alignItems:'center', flexWrap: 'wrap', margin: 0 }}>
        {payload?.filter(entry=>entry.type !== 'none')?.map((entry, index) => (
          <li key={`${entry.value}-legendItem-${index}`} style={{width:'25%',padding:'0.4rem', display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '1rem', borderRadius: '0.2rem', height: '1rem', backgroundColor: entry.color, marginRight: '0.4rem' }}></div>
            <span className={classes.legendItem}>{`${entry.value}`}</span>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
  const zoomFactor = -5;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius + zoomFactor}
        outerRadius={outerRadius }
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

export const calculateRadii = (width, height) => {
  const minDimension = Math.min(width, height);
    // Calculate background radii
    const backgroundOuterRadius = minDimension * 1.2; // 45% of the minimum dimension
    const backgroundInnerRadius = backgroundOuterRadius * 0.45; // 60% of the background outer radius
    
    // Calculate main pie radii
    const outerRadius = minDimension * 1.05; // 40% of the minimum dimension
    const innerRadius = outerRadius * 0.65; // 65% of the outer radius
  
  return {
    outerRadius,
    innerRadius,
    backgroundOuterRadius,
    backgroundInnerRadius
  };
};

const HTTPRequestMethod = ({rawData}) => {

  const {width, height } = useResponsiveDimensions(); // Get responsive dimensions
  const { outerRadius, innerRadius, backgroundOuterRadius, backgroundInnerRadius } = calculateRadii(width, height);


  const data = processData(rawData)
  const classes = useStyles();

  if(data?.length === 0){

    return <div data-testid="test-noHttpRequestMethodsFound" ><NoAttacksFound id="noHttpRequestMethodsFound"/></div>
  }

  return (
    <Box sx={{
      width: '100%',
      height: {xs:'90%',xl:'86%'},
      backgroundColor: '#FFFFFF',
      // boxShadow: '0px 4px 20px 0px #4A556833',
      borderRadius: '0.5rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>

      <ResponsiveContainer width="100%" height="100%">

        <PieChart>
        <svg>
          <circle
            cx="50%"
            cy="47%" // Moved up from 50% to 45%
            r={backgroundOuterRadius}
            fill={BACKGROUND_COLOR}
            stroke="none"
          />
          <circle
            cx="50%"
            cy="47%" // Moved up from 50% to 45%
            r={backgroundInnerRadius}
            fill="white"
            stroke="none"
          />
          </svg>

          <Pie
            activeIndex={-1}
            activeShape={renderActiveShape}
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            fill="#8884d8"
            paddingAngle={2}
            dataKey="value"
            stroke="none" // Remove the stroke
            
          >
            {data?.map((entry, index) => (
              <Cell
                key={`${entry.value}-cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip active={undefined} payload={undefined} coordinate={{ x: 0, y: 0 }} />} />

          <Legend
            content={(props) => renderLegend({ ...props, classes })} 
            layout="horizontal"
            verticalAlign="bottom"
            align="center"
          />
        </PieChart>
        
      </ResponsiveContainer>
    </Box>
  );
};

export default HTTPRequestMethod;


