import React, { useState } from 'react';
import {
  Typography,
  Divider,
  Box,
  TextField,
  Autocomplete,
  InputAdornment
} from '@mui/material';
import { makeStyles } from '@material-ui/core';

import { ReactComponent as DownArrow } from '../../../assets/icons/svg/downArrow.svg';
import NoAttacksFound from '../../NoAttacksFound';


const useStyles = makeStyles((theme) => ({
  root: {
    position:'relative',
    borderRadius: '0.5rem',
    paddingLeft:theme.spacing(0.5),paddingRight:theme.spacing(0.5),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  header: {
    position:'absolute',
    top:-54,right:0,
    width:"30%",

  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    alignItems: 'center',
    fontSize:'0.9rem',width:'100%',
  },
  leftContent: {
    display: 'flex',
    alignItems: 'flex-start',
    // flex: 1,
    width:'78%',
    
  },
  count: {
    color: '#0A0A0A !important',
    paddingRight: theme.spacing(0.5),
    '@media (min-width:2560px)': {
      fontSize: '1.5rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.8rem !important',
    },
  },
  scrollableContent: {
    height: 340,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.3rem',

    },
    '&::-webkit-scrollbar-thumb': {
      background: '#0046FF',
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#CCCCCC',
      backgroundClip: 'padding-box',
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-button': {
      display: 'none',
    },
   
  },
  itemText: {
    width:'84%',
    color:'#404040 !important',
    wordBreak: 'break-word', // Ensures long words will break and wrap to the next line
    whiteSpace: 'normal', // Allows text to wrap and take multiple lines
    overflowWrap: 'anywhere', // Breaks long words anywhere to avoid overflow
  },

  columnTitle:{
    color:'#737373 !important',
    fontSize:"1rem !important",
    '@media (min-width:2560px)': {
      fontSize: '1.5rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.8rem !important',
    },

  },
  textField: {
    '& .MuiAutocomplete-inputRoot': {
      height:30,width:'100%',
      color: '#0046FF', // Change text color
      '& .MuiAutocomplete-input': {
        padding: '10px 14px', // Adjust padding as needed
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#0046FF !important',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#0046FF !important',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#0046FF !important',
    },
  },
  sequenceNumber:{
    paddingRight:theme.spacing(1),
    // minWidth: '1.5rem', // Adjust as needed
  },
  legendDot: {
    marginRight: theme.spacing(1),
  },
  selectIcon: {
    position: 'absolute',
    right: '12px',
    pointerEvents: 'none',
    color: '#0046FF',
  },
  selectIconOpen: {
    transform: 'rotate(180deg)', // Rotate the icon by 180 degrees when the menu is open
  },
}));

const CustomLegendDot = ({id, color }) => (
  <div id={id} data-testid={`${id}-test-legendDot`} style={{ width: '1rem', height: '1rem',marginRight:"0.7rem", backgroundColor: color, borderRadius: '50%' }} />
);

interface ReusableListComponentrops {
  id:string;
  data:any;
  title:string;
  leftColumnHeader:string;
  rightColumnHeader:string;
  showFilter?:boolean;
  filterLabel?:string;
  width:any;
  height?:any;
  legendDotColor?:any;
  showLegendDot?:boolean;
  showSequence?:boolean;
  colors?:string[];
  boxShadow?:string;
}

const ReusableListComponent:React.FC<ReusableListComponentrops> = ({ 
  id,
  data, 
  title, 
  leftColumnHeader, 
  rightColumnHeader,
  showFilter = true,
  filterLabel = "Filters",
  width = '33%',
  legendDotColor,
  showLegendDot = true,
  showSequence = false,
  colors=[],
  height='90%',
  boxShadow = '0px 4px 20px 0px #4A556833',
}) => {
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

    // Filter and sort data based on search term and count
    const filteredData = data
    ?.filter((row: any) =>
      `${row[Object.keys(row)[0]]}`.toLowerCase().includes(searchTerm.toLowerCase())
    ).sort((a: any, b: any) => b.value - a.value);

    


  return (
    <Box id={`reusableComponent-${id}`} data-testid={`${id}-test-reusable-root`} className={classes.root} style={{width,height }}>
      <div id={`${id}-filterContainer`} className={classes.header}>
          {showFilter && (
              <Autocomplete
                sx={{ width: '100%'}}
                id={`${id}-autoCompleteFilter`}
                options={data}
                onChange={(event, value) => setSearchTerm(value ? `${value[Object.keys(value)[0]]}` : '')}
                getOptionLabel={(option:any) => `${option[Object.keys(option)[0]]}`}
                onOpen={() => setOpen(true)} // Set open state to true when menu is opened
                onClose={() => setOpen(false)} // Set open state to false when menu is closed
                renderInput={(params) => (
                  <TextField
                    placeholder={filterLabel}
                    onChange={(e) => setSearchTerm(e.target.value)}
                   
                    
                    {...params}
                    label={''}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <DownArrow data-testid="down-arrow-icon" className={`${classes.selectIcon} ${open ? classes.selectIconOpen : ''}`} />
                      </InputAdornment>
                      ),
                  }}
                />
              )}
            />
          
          )}
        </div>

      <Box id={`${id}-columns`} className={classes.tableHeader}>
        <Typography id={`${id}-leftColumnHeader`} className={classes.columnTitle} variant="h2">{leftColumnHeader}</Typography>
        <Typography id={`${id}-rightColumnHeader`} className={classes.columnTitle}variant="h2">{rightColumnHeader}</Typography>
      </Box>
      <Divider />
      <br/>
      <Box id={`${id}-content`} data-testid={`${id}-test-content`} className={classes.scrollableContent}>
  {(filteredData?.length > 0) ? filteredData?.map((row, index) => {
    if(!row?.name){
        // eslint-disable-next-line array-callback-return
        return 
    }
    return(
    <Box id={`${id}-rowContent`} key={index} className={classes.tableRow}>
      <Box className={classes.leftContent}>
        {showSequence && (
          <Typography id={`${id}-sequence`} className={classes.sequenceNumber}>
            {index + 1}.
          </Typography>
        )}
        {showLegendDot && (
          <Box className={classes.legendDot}>
            <CustomLegendDot 
              id={`${id}-legendDot`}
              color={colors?.length > 0 ? colors[index] : legendDotColor} 
            />
          </Box>
        )}
        <Typography variant='h4' className={classes.itemText}>{`${row[Object.keys(row)[0]]}`}</Typography>
      </Box>
      <Typography fontWeight={550} fontStyle={'normal'} variant='h3' id={`${id}-count`} className={classes.count}>
        {row[Object.keys(row)[1]]}
      </Typography>
    </Box>
  )}) : <NoAttacksFound id={`${id}-noDataAvailable`} />}
</Box>
     
    </Box>
  );
};

export default ReusableListComponent;



