import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  TextField} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CONSTANTS from "../../../constants/constants";
import {
  addOrEditOrDeleteUser,
  getUserDetails,
} from "./userService";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import {updateClientAndUserMapping } from "../Client/clientServices";
import { CustomButton, CustomFormLabel, PopUpHeading } from "../reuseStyles";
import { pushNotification } from "../../../redux/notification/notificationActions";

const useStyles = makeStyles(() => ({
  screenHeadingContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "20px",
    backgroundColor: "#4B6D8F",
    height: "50px",
    color: "#FFFFFF",
  },
  lmsSubmitBtn: {
    backgroundColor: "#4B6D8F !important",
    borderRadius: "35px !important",
    width: "137px",
  },
  lmsDatePicker: {
    height: "39px",
    // width: "300px",
  },
  fieldLabel: {
    marginBottom: "10px",
  },
  scrollBarContainer:{
    overflow:"auto",
    scrollbarWidth: "thin",
    borderRadius:"5px",
    "&::-webkit-scrollbar":{width:2},
    "&::-moz-scrollbar":{width:2},
    "&::-ms-scrollbar":{width:2},
    "&::-webkit-scrollbar-track":{backgroundColor:"#bdc7c0"}

  }
}));

const EditUser = (props: any) => {
  const { setShow, selectedUser,setSelectedUserClient,clientsList,selectedUserData,handleGetAllUsers} = props;

  const dispatch = useDispatch();

  const classes = useStyles();  

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initalStateValues, setInitalStateValues] = useState({
    userName: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    emailId: "",
    clientName:selectedUserData?.clientName,
    department: selectedUserData?.department,
    groups: selectedUserData?.groups,
    roles: selectedUserData?.roles,
  });

  const formOne = useFormik({
    initialValues: initalStateValues,
    validationSchema: Yup.object({
      userName: Yup.string()
        .required("User Name is Requried"),
      firstName: Yup.string().required("First Name is Requried"),
      lastName: Yup.string().required("Last Name is Requried"),
      mobileNumber: Yup.string()
        .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')
        .length(10)
        .required("Mobile Number is Requried"),
      emailId: Yup.string()
        .email("Please Enter a Valid Email Id")
        .required("Email Id is Requried"),
      department:  Yup.string(),
      clientName: Yup.string(),
      groups: Yup.array(),
      roles: Yup.array(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values, setSubmitting);
    },
    enableReinitialize: true,
  });

  const handleSubmit = async (values: any, setSubmitting: any) => {
    setSubmitting(true);
    postFormValues(values);
  };


  const formPayload = (values: any) => ({
    userName: values?.userName,
    firstName: values?.firstName,
    lastName: values?.lastName,
    mobileNumber: values?.mobileNumber,
    emailId: values?.emailId,
    clientName:values?.clientName,
    department: values?.department,
    groups:values?.groups,
    roles:values?.roles
   
  });

  const postFormValues = async(values: any) => {

    const postData = formPayload(values);
    
  
    const client:any = clientsList?.filter((item:any)=>item?.client_name===postData?.clientName)[0]
    const isClientUpdated = values?.clientName === selectedUserData?.clientName

    let success = true
    let message = ""
    let updatedUserDetails = postData
    if(!isClientUpdated){
      const clientUserData = {client_id:client?.client_id}
    
      const result = await updateClientAndUserMapping(selectedUser,clientUserData)
      success =  result?.success
      message = result?.message
      updatedUserDetails = {...postData,clientName:client?.client_name}
    }
   
    if (success) {
      handlePostApiRequest(updatedUserDetails);
      setShow(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: message,
          type: CONSTANTS.SUCCESS,
        })
      );
    } else {
      setShow(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: message,
          type: CONSTANTS.ERROR,
        })
      );
    }

  };

  const handlePostApiRequest = async (data) => {
    const response = await addOrEditOrDeleteUser("update", selectedUser, data);
    if (response?.success) {
      setShow(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.SUCCESS,
        })
      );
      handleGetAllUsers()

    } else {
      setShow(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.ERROR,
        })
      );
    }
  };

  const handlePrefillData = async (selectedUser) => {

    const response:any = await getUserDetails(selectedUser);
    formOne.setFieldValue("userName", response?.data?.userData?.userName);
    formOne.setFieldValue("firstName", response?.data?.userData?.firstName);
    formOne.setFieldValue("lastName", response?.data?.userData?.lastName);
    formOne.setFieldValue(
      "mobileNumber",
      response?.data?.userData?.mobileNumber
    );
    formOne.setFieldValue("emailId", response?.data?.userData?.emailId);
    formOne.setFieldValue("clientName", response?.data?.userData?.clientName)
    formOne.setFieldValue("department", response?.data?.userData?.department);
  
  };

  useEffect(() => {
    handlePrefillData(selectedUser);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);
 
  return (
    <Box padding={"2%"}>
      <form onSubmit={formOne.handleSubmit}>
        <Box 
          className={classes.scrollBarContainer}
          sx={{
           position:"absolute",
           top: "50%",
           left: "50%",
           transform: "translate(-50%, -50%)",
           backgroundColor:"#FFFFFF",
           overflow:"auto",
           "@media (max-width:600px)":{
            width:"18rem",height:"20rem"
          },
          "@media (min-width:601px)":{
            width:"24rem",height:"22rem"
          },
          "@media (min-width:768px)":{
            width:"30rem",height:"25rem"
          },
          "@media (min-width:1024px)":{
            width:"38rem",height:"26rem"
          },
          "@media (min-width:1366px)":{
            width:"44rem",height:"28rem"
          },
          "@media (min-width:2560px)":{
            width:"65rem",height:"30rem"
          },
          "@media (min-width:3200px)":{
            width:"65rem",height:"30rem"
          },

           
        }}>
          <Box className={classes.screenHeadingContainer}>
            <PopUpHeading variant='h4' ml={"0.8rem"}>Edit User</PopUpHeading>
            <Box>
              <Button
                sx={{ color: "#FFFF" }}
                onClick={() => {
                  setShow(false);
                }}
              >
                <CloseOutlinedIcon sx={{fontSize:"1.2rem"}}/>
              </Button>
            </Box>
          </Box>
          <Box className="formContainer-Tm">
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              mt={"2%"}
              gap={"1rem"}
              p={"0.8rem"}
            >
              <Box display={"flex"} flexDirection={"column"}>
                <CustomFormLabel>User Name</CustomFormLabel>
                <TextField
                  inputProps={{ "data-testid": "userName" }} // Ensure you are adding the data-testid to the input element
                  disabled
                  id="User-Name"
                  name="userName"
                  size="small"
                  onBlur={formOne?.handleBlur}
                  onChange={formOne?.handleChange}
                  value={formOne?.values?.userName}
                  helperText={
                    formOne?.touched?.userName && formOne?.errors?.userName
                  }
                  error={
                    formOne?.touched?.userName && Boolean(formOne?.errors?.userName)
                  }
                  sx={{ width: "100%" }}
                />
              </Box>

              <Box display={"flex"} flexDirection={"column"}>
                <CustomFormLabel>First Name</CustomFormLabel>
                <TextField
                  inputProps={{ "data-testid": "firstName" }} // Ensure you are adding the data-testid to the input element
                  id="first-Name"
                  name="firstName"
                  size="small"
                  onBlur={formOne?.handleBlur}
                  onChange={formOne?.handleChange}
                  value={formOne?.values?.firstName}
                  helperText={
                    formOne?.touched?.firstName && formOne?.errors?.firstName
                  }
                  error={
                    formOne?.touched?.firstName &&
                    Boolean(formOne?.errors?.firstName)
                  }
                  sx={{ width: "100%" }}
                />
              </Box>

              <Box display={"flex"} flexDirection={"column"}>
                <CustomFormLabel>Last Name</CustomFormLabel>
                <TextField
                  inputProps={{ "data-testid": "lastName" }} // Ensure you are adding the data-testid to the input element
                  id="last-Name"
                  name="lastName"
                  size="small"
                  onBlur={formOne?.handleBlur}
                  onChange={formOne?.handleChange}
                  value={formOne?.values?.lastName}
                  helperText={
                    formOne?.touched?.lastName && formOne?.errors?.lastName
                  }
                  error={
                    formOne?.touched?.lastName && Boolean(formOne?.errors?.lastName)
                  }
                  sx={{ width: "100%" }}
                />
              </Box>

              <Box display={"flex"} flexDirection={"column"}>
                <CustomFormLabel>Mobile Number</CustomFormLabel>
                <TextField
                  inputProps={{ "data-testid": "mobileNumber" }} // Ensure you are adding the data-testid to the input element

                  id="mobile-Number"
                  name="mobileNumber"
                  size="small"
                  onBlur={formOne?.handleBlur}
                  onChange={formOne?.handleChange}
                  value={formOne?.values?.mobileNumber}
                  helperText={
                    formOne?.touched?.mobileNumber && formOne?.errors?.mobileNumber
                  }
                  error={
                    formOne?.touched?.mobileNumber &&
                    Boolean(formOne?.errors?.mobileNumber)
                  }
                  sx={{ width: "100%" }}
                />
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
                <CustomFormLabel>Email</CustomFormLabel>
                <TextField
                  inputProps={{ "data-testid": "emailId" }} // Ensure you are adding the data-testid to the input element
                  disabled
                  id="email-Id"
                  name="emailId"
                  size="small"
                  onBlur={formOne?.handleBlur}
                  onChange={formOne?.handleChange}
                  value={formOne?.values?.emailId}
                  helperText={formOne?.touched?.emailId && formOne?.errors?.emailId}
                  error={
                    formOne?.touched?.emailId && Boolean(formOne?.errors?.emailId)
                  }
                  sx={{ width: "100%" }}
                />
              </Box>
          
          <Autocomplete
            id="clientName"
            options={clientsList}
            getOptionLabel={(option) => option?.client_name || ""}
            value={clientsList?.find((client:any) => client?.client_name === formOne?.values?.clientName) || null}
            onChange={(event, newValue) => {
              formOne.setFieldValue("clientName", newValue ? newValue.client_name : "");
            }}
            onInputChange={(event, newInputValue) => {
              setSelectedUserClient(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="clientName"
                size="small"
                value={formOne?.values?.clientName}
                onBlur={formOne.handleBlur}
                error={formOne.touched.clientName && Boolean(formOne.errors.clientName)}
                helperText={formOne.touched.clientName && formOne.errors.clientName}
              />
            )}
          />
            <Box display={"flex"} flexDirection={"column"}>
                <CustomFormLabel>Job title</CustomFormLabel>
                <TextField
                  inputProps={{ "data-testid": "department" }} // Ensure you are adding the data-testid to the input element
                  id="department"
                  name="department"
                  size="small"
                  onBlur={formOne?.handleBlur}
                  onChange={formOne?.handleChange}
                  value={formOne?.values?.department}
                  helperText={formOne?.touched?.department && formOne?.errors?.department}
                  error={
                    formOne?.touched?.department && Boolean(formOne?.errors?.department)
                  }
                  sx={{ width: "100%" }}
                />
              </Box>
            
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
            >
              <Box display={"flex"} flexDirection={"column"} mb={"2rem"}>
                <CustomButton
                  data-testid="update-button"
                  variant="contained"
                  type="submit"
                  disabled={formOne.isSubmitting}
                  sx={{COLOR:'#FFFFFF',textTransform:"none"}}
                >
                  Update
                </CustomButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default EditUser;


