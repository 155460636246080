
import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as Refresh } from '../../../assets/icons/svg/refresh.svg';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core';
import Logo from './Logo';
import { useDispatch, useSelector } from 'react-redux';
import CONSTANTS from '../../../constants/constants';
import { getMenusApi } from '../../../services/appServices';
import { domainsByClientApi } from '../../../services/dashboardApis';
import { setIsRefresh } from '../../../redux/loader/loadingActions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
   
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    width: '100% !important',
    backgroundColor: '#ffffff',
    borderBottom: '1px solid #CCCCCC',
    minHeight: '40px !important', // Adjust the value to your preferred height
    [theme.breakpoints.up('sm')]: {
      minHeight: '60px !important', // Adjust the value for larger screens
    },
     '@media (min-width:2560px)': {
      minHeight: '80px !important', // Adjust the value for larger screens
    },
    '@media (min-width:3840px)': {
      minHeight: '90px !important', // Adjust the value for larger screens

    },
  },
  rightSectionBox:{
    display: 'flex',
    alignItems:'center',
    [theme.breakpoints.down('sm')]: {
      width:'30%',
    },
    [theme.breakpoints.down('md')]: {
      width: '45%',
      justifyContent:'flex-end'
    },
  },
  rightSection: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection:'column'
  },
  blockedMode: {
    color: 'red',
    marginRight: theme.spacing(2),
  },
  time: {
    color: '#6E7079'
  },
}));

interface HeaderProps {
  id: string;
}

const Header: React.FC<HeaderProps> = ({ id }) => {
  const classes = useStyles();
  
  const {domain} = useSelector((state: any) => state.domainData);
  const {isRefresh} = useSelector((state: any) => state.loaders);

  const dispatch = useDispatch()
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleString());

  const domainInStorage:any = sessionStorage.getItem(CONSTANTS.DOMAIN_OBJ);

  const storedDomain = domainInStorage ? JSON.parse(domainInStorage) : domain;
  
  const isDashboard = window?.location?.pathname === CONSTANTS.NAVIGATE_TO_DASHBOARD


  useEffect(() => {
    setCurrentTime(new Date().toLocaleString());
  }, [isRefresh]);

  useEffect(()=>{ 
    getMenusApi(dispatch)
    domainsByClientApi(dispatch)

  },[dispatch])
  const handleRefreshClick = () => {
    dispatch(setIsRefresh()); // Toggle the current isRefresh state
  };

  return (
    <div id={id} data-testid={`${id}-appBar`}  className={classes.root}>
      <AppBar id={`${id}-appBar`} sx={{ boxShadow: 'none' }} position="static">
        <Toolbar id={`${id}-toolbar`} className={classes.toolbar}>
          <Logo id={`${id}-logo`} />
          <div className={classes.title}></div>
          {isDashboard && <div className={classes.rightSectionBox}>
            <div className={classes.rightSection}>
            <Typography variant="h4" color="#FF4B55" data-testid={`${id}-blockedMode`} id={`${id}-blockedMode`} className={classes.blockedMode}>
              {storedDomain?.current_mode === 'On'?'Block Mode':'Transparent Mode'}
            </Typography>
            <Typography variant="h4" id={`${id}-time`} className={classes.time}>
              Last Updated: {currentTime}
            </Typography>
            
            </div>
              <IconButton sx={{pl:2.2}} aria-label='Refresh' title='Refresh' data-testid="refreshButton" id="refreshButton" onClick={handleRefreshClick} size="small">
                <Refresh />
              </IconButton>
          </div>}
    
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
