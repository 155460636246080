import React, { useEffect, useState } from 'react';
import {InputAdornment, Box } from '@mui/material';
import { ReactComponent as DomainLinkIcon } from '../../assets/icons/svg/carbon_link.svg';

import { useDispatch, useSelector } from 'react-redux';
import { setDomain } from '../../redux/domainData/domainActions';

import CONSTANTS from '../../constants/constants';
import CustomSelect from '../../components/common/customSelect';
import DurationPicker from '../../components/common/customDate/DurationPicker';




const DomainControl = ({id,domain,setPage}) => {
  const [selectedDomain, setSelectedDomain] = useState<any>({});

  const {domainsByClient} = useSelector((state: any) => state?.domainData);
  const dispatch = useDispatch();

  useEffect(() => {
    const storedItem = JSON.parse(sessionStorage.getItem(CONSTANTS.DOMAIN_OBJ) ?? '{}');
    if (storedItem?.domain_id) {
      setSelectedDomain(storedItem);
    } else {
      setSelectedDomain(domain);
    }

  }, [domain,domain?.domain_id]);

  const handleDomainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDomainObj = event?.target?.value
    setSelectedDomain(JSON.parse(selectedDomainObj));
    sessionStorage.setItem(CONSTANTS.DOMAIN_OBJ, selectedDomainObj);
    dispatch(setDomain(JSON.parse(selectedDomainObj)));
    setPage(1)
  };


  
  return (
     <Box id={id} display="flex" gap={2} alignItems="center">
      <CustomSelect
        data-testid='custom-select'
        id={`${id}-textField`}
        options={domainsByClient?.map((eachDomain) => ({
          value: JSON.stringify(eachDomain),
          label: eachDomain?.domain_url,
        }))}
        value={JSON.stringify(selectedDomain)}
        onChange={handleDomainChange}
        startAdornment={
          <InputAdornment position="start">
            <DomainLinkIcon />
          </InputAdornment>
        }
      />
      <Box sx={{width:{sm:'100%',md:'70%',lg:'72%'}}}><DurationPicker setPage={setPage}/></Box>
    </Box>
   
     
  );
};

export default DomainControl;
