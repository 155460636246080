import moment from 'moment-timezone';


export const convertToIST = (gmtTimeString) => {
    const gmtDate = new Date(gmtTimeString);
    if(isNaN(gmtDate.getTime())) {
        return 'Invalid Date';
    }
    let ISTString =  gmtDate.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', 
    
        }).replace(/\//g, '-');

        // Extracting AM/PM and converting to uppercase
        const ampm = ISTString.slice(-2).toUpperCase();
        ISTString = ISTString.slice(0, -2) + ampm;

        return ISTString;


    };



export const calculateRange = (range): { from: string; to: string } => {
    const end = moment().utc();
    let start = moment().utc();

    switch (range) {
        case '12h':
        start = end.clone().subtract(12, 'hours');
        break;
        case '24h':
        start = end.clone().subtract(24, 'hours');
        break;
        case 'week':
        start = end.clone().subtract(1, 'week');
        break;
        case 'month':
        start = end.clone().subtract(1, 'month');
        break;
        case 'year':
        start = end.clone().subtract(6, 'months'); // Changed to 6 months
        break;
    }

    return {
        from: start.format('YYYY-MM-DDTHH:mm:ss[Z]'),
        to: end.format('YYYY-MM-DDTHH:mm:ss[Z]')
    };
};


export const dateFormat = (date) =>{
    return moment(date).format('DD-MM-YYYY');
}



export const convertAndFormatDate = (date) =>{

    const formattedDate = moment.tz('Asia/Kolkata').format('DD/MM/YYYY');

    return formattedDate

}



// Function to format and encode the datetime
export const formatAndEncodeDate = (date) => {
    const formattedTime = date.format('YYYY-MM-DDTHH:mm:ssZ');
    const offset = date.format('Z');
    const encodedOffset = offset.replace(/:/g, '').replace(/\+/g, '%2B');
    return formattedTime.replace(offset, encodedOffset);
};



export const getDateTimeForLabel = (label) => {

     let start = moment.tz('Asia/Kolkata')
     const end = moment.tz('Asia/Kolkata')

    switch (label) {
        case '12h':
         start = formatAndEncodeDate(end.clone().subtract(12, 'hours'));
        break
        case '24h':
            start =  formatAndEncodeDate(end.clone().subtract(24, 'hours'));
        break

        case 'week':
            start =  formatAndEncodeDate(end.clone().subtract(1, 'week'));
        break

        case 'month':
            start =  formatAndEncodeDate(end.clone().subtract(1, 'month'));
        break
        case 'year':
            start =  formatAndEncodeDate(end.clone().subtract(6, 'months'));
        break
    }
    return {
        from: start,
        to: formatAndEncodeDate(end)
    };
};


export const convertToISTFormat = (date)=>{
    return formatAndEncodeDate(moment(date))
}


export const dateSlashFormat = (date) =>{
    // console.log("Date in slash ",(date))
    return moment(date).format('DD/MM/YYYY');
}


// Function to extract the date part from an encoded datetime string
export const getDatePart = (encodedDatetime) => {
    // Decode the URL-encoded '+' sign
    const decodedDatetime = decodeURIComponent(encodedDatetime);
    
    // Parse the datetime string
    const dateMoment = moment(decodedDatetime, 'YYYY-MM-DDTHH:mm:ssZ');
    
    // Extract and return the date part
    return dateMoment.format('DD/MM/YYYY');
};


// Function to convert and format the datetime with AM/PM
export const formatDateWithAMPM = (dateString) => {
    // Parse the datetime string in IST
    const dateMoment = moment.tz(dateString, 'YYYY-MM-DD HH:mm:ss', 'Asia/Kolkata');
    
    // Format the datetime with AM/PM notation
    return dateMoment.format('YYYY-MM-DD hh:mm:ss A');
};


// Function to get the difference in days
export const getDaysDifference =(date1, date2)=> {
    // Decode URL-encoded timezone offset
  const decodedDate1 = decodeURIComponent(date1);
  const decodedDate2 = decodeURIComponent(date2);
  
  // Parse the dates with timezone information
  const format = 'YYYY-MM-DDTHH:mm:ssZ'; // Adjust format as necessary
  const startDate = moment.tz(decodedDate1, format, 'Asia/Kolkata'); // Example timezone
  const endDate = moment.tz(decodedDate2, format, 'Asia/Kolkata'); // Example timezone
  
  // Calculate the difference in days, including fractions
  return Math.ceil(endDate.diff(startDate, 'days',true))
  }



  // Function to convert and format the datetime string
export const convertDateTimeString = (dateTimeStr) => {
    // Decode the `%2B` to `+`
    let decodedStr = decodeURIComponent(dateTimeStr);
    
    // Extract the offset
    const offsetMatch = decodedStr.match(/([+-]\d{4})$/);
    
    if (offsetMatch) {
        const offset = offsetMatch[0];
        // Format the offset to `+HH:MM` or `-HH:MM`
        const formattedOffset = offset.slice(0, 3) + ':' + offset.slice(3);
        
        // Replace the old offset format with the new formatted one
        return decodedStr.replace(offset, formattedOffset);
    }
    
    // Return the decoded string if no offset was found
    return decodedStr;
};