import CONSTANTS from "../../../constants/constants";
import { GET_CLIENT_URLS,
    SEND_REPORT,
    getClientsDomainsListEndpoint,
    getDeleteClientEndpoint,
    ADD_CLIENT_API_ENDPOINT,
    getClientUpdationEndpoint,
    ADD_CLIENT_DOMAIN_API_ENDPOINT,
    getDeleteDomainEndpoint,
    DELETE_CLIENT_USER_MAPPING_API_ENDPOINT,
    getEditDomainEndpoint,
    CLIENT_USER_MAPPING_ENDPOINT

} from "../../../constants/endpoints";
import { pushNotification } from "../../../redux/notification/notificationActions";

import { request } from "../../../services/request";
const URL = `${process.env.REACT_APP_CYBERWASP_GATEWAY_URL}`

export const getAllPaginatedClients = async (page,rowsPerPage,searchBy,searchTerm) => {

    try{

        const search = searchTerm ? `&${searchBy}=${searchTerm}` : "";


         
        const CLIENTS_LIST_API_ENDPOINT = getClientsDomainsListEndpoint(page,rowsPerPage,search)
     
        const response:any = await request.get(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${CLIENTS_LIST_API_ENDPOINT}`) 

        return response
    
    }

    catch(error){

        return error

    }   

}

export const addOrEditOrDeleteClient = async(
    
    action: "add-client" | "update" | "delete" | "add-domain" | "edit-domain",

    clientId?: string | null,

    data?: any | null,

    domainId?:string

)=>{


    if(action === "delete"){
        
        const DELETE_CLIENT_URL = getDeleteClientEndpoint(clientId)
        const response:any = await request.delete(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${DELETE_CLIENT_URL}`)
     
        return response

    }

    if(action === "update"){

        console.log("data to update ",data)

        const UPDATE_CLIENT_API_ENDPOINT = getClientUpdationEndpoint(clientId)
        const response:any = await request.put(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${UPDATE_CLIENT_API_ENDPOINT}`,data)
        return response

    }

    if(action === "add-domain"){


        const response:any = await request.post(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${ADD_CLIENT_DOMAIN_API_ENDPOINT}`,data)

        return response

    }
    if(action === "add-client"){
        
        const response:any = await request.post(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${ADD_CLIENT_API_ENDPOINT}`,data)

        return response

    }

    if(action === "edit-domain"){
        // console.log("data in edit api ",data)

        const UPDATE_DOMAIN_API_ENDPOINT = getEditDomainEndpoint(domainId)
        
        const response:any = await request.put(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${UPDATE_DOMAIN_API_ENDPOINT}`,data)

        return response


    }

}

export const getAllClientUrls = async (clientId)=>{
    const search = clientId ? `?search=${clientId}` : "";
    try{

        const response:any = await request.get(`${URL}${GET_CLIENT_URLS}${search}`)

        return response.data

    }

    catch(error){

        return
    }

}
export const deleteUserFromClient = async (userId)=>{
    const response:any =  await request.delete(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${DELETE_CLIENT_USER_MAPPING_API_ENDPOINT}/${userId}`)

    return response

}
export const deleteUrlFromClient = async (domainId)=>{

    const DELETE_URL_FROM_CLIENT_API_ENDPOINT = getDeleteDomainEndpoint(domainId)
   const response:any =  await request.delete(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${DELETE_URL_FROM_CLIENT_API_ENDPOINT}`)

    return response

}
export const editUrlInClient =async (domainId,data,dispatch)=>{

    const UPDATE_DOMAIN_API_ENDPOINT = getEditDomainEndpoint(domainId)

    const response:any = await request.put(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${UPDATE_DOMAIN_API_ENDPOINT}`,data)
    
    if (response?.success) {
        dispatch(
        pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.SUCCESS,
        })
        );
    } else {
        
        dispatch(
        pushNotification({
            isOpen: true,
            message: response.error,
            type: CONSTANTS.ERROR,
        })
        );
    }

}
export const mapUserAndClient =async (data)=>{

    const response:any = await request.post(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${CLIENT_USER_MAPPING_ENDPOINT}`,data)
    return response

}

export const updateClientAndUserMapping =async (userId,data)=>{

    const response:any = await request.put(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${CLIENT_USER_MAPPING_ENDPOINT}/${userId}`,data)
    return response

}



export const sendReportApi = async(send_data) =>{

    const response:any = await request.post(`${URL}${SEND_REPORT}`,send_data)
    return response

}