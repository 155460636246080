import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Checkbox, FormControlLabel, Popover, TextField } from '@mui/material';
import { addOrEditOrDeleteClient } from './clientServices';
import { useDispatch } from 'react-redux';
import { pushNotification } from '../../../redux/notification/notificationActions';
import CONSTANTS from '../../../constants/constants';

// Define Yup validation schema
const validationSchema = Yup.object({
    domain_name: Yup.string()
});



const EditDomain = ({clientId,handleGetPaginatedClients,isEditing,onClose,anchorEl,domainInfo}) => {


// Define the initial values for the form
const initialValues = {
    // client_id: clientId,
    domain_name: domainInfo?.domain_name,
    domain_url: domainInfo?.domain_url,
    ip_address:domainInfo?.ip_address,
    current_mode:domainInfo?.current_mode,
    hosting_service_name: domainInfo?.hosting_service_name,
    dns_mapped:domainInfo?.dns_mapped,
    mapped_on:domainInfo?.mapped_on,
};

const dispatch = useDispatch()

const handleEditDomainApi = async (postData) =>{
    const response: any = await addOrEditOrDeleteClient("edit-domain", clientId , postData, domainInfo?.domain_id);
    if (response?.success) {
      if (handleGetPaginatedClients) {
        handleGetPaginatedClients();
      }
      dispatch(pushNotification({
        isOpen: true,
        message: response?.message,
        type: CONSTANTS.SUCCESS,
      }));
      onClose();
    } else {
      dispatch(pushNotification({
        isOpen: true,
        message: response?.detail?.message,
        type: CONSTANTS.ERROR,
      }));
    }
  }
  // Handle form submission
  const handleSubmit = (values) => {

    const editedDomainData = {
        current_mode:values.current_mode,
        domain_name:values.domain_name,
        dns_mapped:values.dns_mapped,
        domain_url:values.domain_url,
        hosting_service_name:values.hosting_service_name,
        ip_address:values.ip_address
    }
   
    handleEditDomainApi(editedDomainData)
  };

  return (
      
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
  {({ isSubmitting, values, handleChange, handleBlur, touched, errors }) => (
          <Form style={{ display: "flex", flexDirection: "column", padding: "1rem" }}>
            <TextField
              id="domain_name"
              name="domain_name"
              label="Domain Name"
              size="small"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.domain_name}
              helperText={touched.domain_name && errors.domain_name}
              error={touched.domain_name && Boolean(errors.domain_name)}
              sx={{ marginBottom: "1rem" }}
            />
            <TextField
              contentEditable={false}
              disabled
              id="domain_url"
              name="domain_url"
              label="Domain URL"
              size="small"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.domain_url}
              helperText={touched.domain_url && errors.domain_url}
              error={touched.domain_url && Boolean(errors.domain_url)}
              sx={{ marginBottom: "1rem" }}
            />
            <TextField
              id="ip_address"
              name="ip_address"
              label="IP Address"
              size="small"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.ip_address}
              helperText={touched.ip_address && errors.ip_address}
              error={touched.ip_address && Boolean(errors.ip_address)}
              sx={{ marginBottom: "1rem" }}
            />
            <TextField
              contentEditable={false}
              disabled
              id="current_mode"
              name="current_mode"
              label="Current Mode"
              size="small"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.current_mode === 'Off'?'Transparent Mode':'Block Mode'}  // Example value binding
              helperText={touched.current_mode && errors.current_mode}
              error={touched.current_mode && Boolean(errors.current_mode)}
              sx={{ marginBottom: "1rem" }}
            />
            <TextField
              id="hosting_service_name"
              name="hosting_service_name"
              label="Hosting Service Name"
              size="small"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.hosting_service_name}
              helperText={touched.hosting_service_name && errors.hosting_service_name}
              error={touched.hosting_service_name && Boolean(errors.hosting_service_name)}
              sx={{ marginBottom: "1rem" }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="dns_mapped"
                  name="dns_mapped"
                  checked={values.dns_mapped}
                  onChange={handleChange}
                />
              }
              label="DNS Mapped"
              sx={{ marginBottom: "1rem" }}
            />
            <TextField
              contentEditable={false}
              disabled
              id="mapped_on"
              name="mapped_on"
              label="Mapped On"
              type="text"
              size="small"
            //   onBlur={handleBlur}
            //   onChange={handleChange}
              value={values.mapped_on}
            //   helperText={touched.mapped_on && errors.mapped_on}
            //   error={touched.mapped_on && Boolean(errors.mapped_on)}
              sx={{ marginBottom: "1rem" }}
            />
            <Button type="submit" variant="contained" sx={{color:'#FFFFFF'}} disabled={isSubmitting}>
              Edit Domain
            </Button>
          </Form>
        )}
    </Formik>
    </Popover>
  );
};

export default EditDomain;
