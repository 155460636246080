import { Box, Chip, Skeleton, Typography } from "@mui/material";
import '../../styles/DashboardStyles.css'
import CustomSelect from "../../components/common/customSelect";
import { makeStyles } from "@material-ui/core";

interface PlaceholderComponentProps {
  filterValue?:string;
  text:string;
  height?: {
    xs?: number;
    sm?:number;
    md?: number;
    lg?:number;
    xl?: number;
  };
  children:any;
  padding?:any;
  filterOptions?: any;
  marginBottom?:any;
  isChipPresent?:boolean;
  setFilterValue?: any;
  loading?:boolean;
  id:string;
  boxShadow?:string;
  isFetchingData?:boolean;
  description?:string;
}

const useStyles = makeStyles((theme) => ({
  description:{
    paddingLeft:2,color:'#696969',
    fontSize:'0.8rem',
    '@media (min-width:2560px)': {
      fontSize: '1.5rem',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.8rem',
    },
  }

}))
export const PlaceholderComponent:React.FC<PlaceholderComponentProps> = ({id,loading=false,isChipPresent=false,
    setFilterValue,marginBottom=2,
    boxShadow = "0px 4px 20px 0px #4A556833",
    isFetchingData=false,description="",
    filterValue,text, height, children,padding=2.4,filterOptions = [] }) => {

      const classes = useStyles()
  const handleFilterChange = (event) => {
    const value = event.target.value;
    setFilterValue(value); // Update filter value directly using setFilterValue
  };
  if(loading && !isFetchingData){
    return  <Skeleton  id={`${id}-skeleton`} variant="rectangular" width="100%" sx={{ borderRadius:'0.5rem',height: {...height}}} animation="wave" />

  }
  return(
    <Box 
        id={id} 
        sx={{
          backgroundColor:'#FFFFFF',
          boxShadow, 
          borderRadius:'0.5rem', 
          height:{...height},
          // height: { xs: (height?.xs), md: height?.md, xl: height?.xl },
          padding,
          overflow: 'hidden' }}>
      
    <Box id={`${id}-titleBox`} sx={{pt:1.2,display: 'flex', justifyContent: 'space-between', alignItems: 'center',flexWrap:"wrap", mb: marginBottom }}>
      <Typography id={`${id}-title`} sx={{pl:1.5,color:'#1B1B29'}} variant="h2" gutterBottom>
        {text}
        {description?<span className={classes.description}> ({description})</span>:''}
        {isChipPresent && <Chip label="Blocked" size="small" 
        sx={{background: '#FF4B5529',color:'#FF4B55',ml: 1 }} />}
        </Typography>
      {filterOptions?.length>0 && 
        <CustomSelect  
          id={`${id}-customFilter`}
          options={filterOptions} 
          width={'20%'} 
          height={35}
          color={'#0046FF'}
          value={filterValue} 
          onChange={handleFilterChange}        />
        
          }

    </Box>
    
    {children}
  </Box>
  )

}
