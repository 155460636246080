import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Autocomplete,
  Button} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ReactComponent as DownArrow } from '../../../assets/icons/svg/downArrow.svg';
import { attacksInformationApi } from '../../../services/dashboardApis';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  buttonLabel:{
    fontSize:'1rem !important',
    '@media (min-width:2560px)': {
      fontSize:'1.5rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize:'1.8rem !important',
    },
  }

}))

const FilterModal = ({ searchTerm, severity, setSeverity, countries, setCountries, open, onClose,setPage, page, domainId, startAndEndDateTime }) => {
  const [countriesOptions, setCountriesOptions] = useState<string[]>([]);
  const dispatch = useDispatch();

  const handleSeverityChange = (event, newSeverity) => {
    setSeverity(newSeverity);

  };

  const handleInputChange = (event, value) => {
    if (value && !countriesOptions.includes(value)) {
      setCountriesOptions(prevOptions => [...prevOptions, value]);
    }
  };

  const handleCountriesChange = (event, newValue) => {
    setCountries(newValue);
  };

  const handleClearFilters = () => {
    setSeverity([]);
    setCountries([]);
    attacksInformationApi(dispatch, page, domainId, startAndEndDateTime, searchTerm, [], []);
    onClose();
  };

  const handleClickApplyFilters = () => {
    setPage(1)
    const lowerCaseCountries = countries?.map((val: string) => val.toLowerCase());
    const lowerCaseSeverities = severity?.map((val: string) => val.toLowerCase());
    const params = {
      countriesFilter: lowerCaseCountries.join(','),
      severitiesFilter: lowerCaseSeverities.join(','),
    };
    attacksInformationApi(dispatch, page, domainId, startAndEndDateTime, searchTerm, params?.countriesFilter, params?.severitiesFilter);
    onClose();
  };

  const classes = useStyles()

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="filter-modal-title"
        aria-describedby="filter-modal-description"
      >
        <Box sx={{ p: 2, width: {xs:500,xl:800}, bgcolor: 'background.paper', margin: 'auto', mt: '10%', borderRadius: 1 }}>
          
          <Box gap={2} sx={{ pt: 2, display: 'flex', flexDirection: 'column' }}>
            <Box gap={4} sx={{ pt: 2, display: 'flex', alignItems: 'center' }}>
              <Typography variant="h2" gutterBottom>
                Severity:
              </Typography>
              <Autocomplete
                size='small'
                multiple
                fullWidth
                id="severity"
                options={["High", 'Medium', 'Low']}
                value={severity}
                onChange={handleSeverityChange}
                popupIcon={<DownArrow />} // Custom down arrow icon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select severity"
                  />
                )}
              />
            </Box>
            <Box gap={4} sx={{ pt: 3, display: 'flex', alignItems: 'center' }}>
              <Typography variant="h2" gutterBottom>
                Country:
              </Typography>
              <Autocomplete
                size="small"
                fullWidth
                multiple
                id="countries"
                options={countriesOptions}
                value={countries}
                onChange={handleCountriesChange}
                onInputChange={handleInputChange}
                popupIcon={<DownArrow />} // Custom down arrow icon
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Search countries"
                  />
                )}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button data-testid="clear-search-button" className={classes.buttonLabel} onClick={handleClearFilters} sx={{ pl: 0, textDecoration: 'underline', lineHeight: 2, color: '#0046FF' }}>
                Clear Filters
              </Button>
              <Box>
                <Button className={classes.buttonLabel} onClick={onClose} sx={{ mr: 1, backgroundColor: '#EFEFEF', color: '#1B1B29' }}>
                  Cancel
                </Button>
                <Button className={classes.buttonLabel} variant="contained" sx={{ color: '#FFFFFF', backgroundColor: '#0046FF' }}
                  onClick={handleClickApplyFilters}>
                  Apply
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </LocalizationProvider>
  );
};

export default FilterModal;
