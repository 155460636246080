import { format } from "date-fns";
import { getDaysDifference } from "./convertTime";

// Exporting the function
export const formatXAxis = (tickItem: any, index: number, label: string, startAndEndDate: { from: Date, to: Date }) => {
    const date = new Date(tickItem);
    const start = startAndEndDate?.from;
    const end = startAndEndDate?.to;
  
    const diffDays = getDaysDifference(start, end);
  
    let formatString: string;
  
    switch (label) {
        case '12h':
        case '24h':
          formatString = 'hh a';
  
          return format(date, 'hh a');
        case 'week':
          formatString = 'dd/MM';
          return  format(date, formatString);
        case 'month':
          formatString = 'MMM/dd';
          return  format(date, formatString);
        case 'year':
          formatString = 'dd/MMM/yyyy';
          return  format(date, formatString);
        default:
          if (diffDays < 2) {
  
            formatString = 'dd/MM hha';
          } else if (diffDays >= 2 && diffDays <= 10) {
            formatString = 'dd/MM';
          } else if (diffDays >= 11 && diffDays <= 32) {
            formatString = 'dd/MMM';
          } else if (diffDays >= 32 && diffDays <= 180) {
              formatString = 'dd/MMM';
          } else {
            formatString = 'dd/MMM/yyyy';
          }
          return format(date, formatString);
      }
  };