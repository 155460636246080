import { styled, TablePagination, Tooltip, tooltipClasses, TooltipProps, Zoom } from "@mui/material";

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip  
      arrow 
      followCursor
      disableInteractive
      TransitionComponent={Zoom}
      {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[2],
      fontSize: "1rem",
     
    },
    
  }));



 export const CustomTablePagination = styled(TablePagination)(({ theme}) => ({
    
  '& .MuiTablePagination-toolbar':{
    display:"flex",
    flexWrap:"wrap",
    justifyContent:"center",
    alignItems:"center",
    margin:0,
    padding:0
  },
  
    '&.MuiTablePagination-root':{
      color:'#22323b',
     
    
    },

    '& .MuiTablePagination-select': {
     
      fontWeight:"580",
      lineHeight:'2.4rem',
      fontSize:'1rem',

      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: '1rem',
        lineHeight:'2.5rem',
      },

      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: "1.2rem",
        lineHeight:'2.6rem',
      },
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: "1.2rem",
        lineHeight:'2.8rem',
      },
      [theme.breakpoints.between('lg', 'xl')]: {
        fontSize: "1.2rem",
        lineHeight:'2.5rem',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: "1.8rem",
        lineHeight:'5rem',
      },
      "@media (min-width:1536px) and (max-width:2559px)":{
        fontSize: "1.2rem",
      },

      "@media (min-width:2560px) and (max-width:3200px)":
      {fontSize: "1.6rem",},
   
      // // color: 'red',
      // // '&:before': {
      // //   borderColor: 'blue',
      // // },
      // // fontSize:"1.5rem",
      

    },
    '& .MuiTablePagination-displayedRows':{
      margin:0,
      padding:0,
      
      fontWeight:"580",
      fontSize:"1rem",
      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: '1rem',
        lineHeight:'2.5rem',
        
      },

      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: "1.1rem",
        lineHeight:'2.6rem',
      },
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: "1.2rem",
        lineHeight:'2.8rem',
      },
      [theme.breakpoints.between('lg', 'xl')]: {
        fontSize: "1.2rem",
        lineHeight:'3rem',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: "1.8rem",
        lineHeight:'3.2rem',
      },
      "@media (min-width:1536px) and (max-width:2559px)":{
        fontSize: "1.2rem",
        lineHeight:'3.2rem',
      },

      "@media (min-width:2560px) and (max-width:3200px)":{
        fontSize: "1.8rem",
        lineHeight:'3rem',
      }
   
    },
    '& .MuiTablePagination-selectLabel':{
      margin: theme.spacing(0), // Use theme.spacing for margin
      padding: theme.spacing(0),
      
      fontWeight:"580",
      fontSize:"1rem",

      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: '0.9rem',
     
        
      },

      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: "1.1rem",
      },
      [theme.breakpoints.between('lg', 'xl')]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: "1.8rem",
      },
      "@media (min-width:1536px) and (max-width:2559px)":{
        fontSize: "1.3rem",
      },
      "@media (min-width:2560px) and (max-width:3200px)":{
        fontSize: "1.8rem",
      }
      

    },

    "& .MuiSvgIcon-root":{
     
    fontSize:"1rem",
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '1.1rem',
     
    },

    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: "1.8rem",
    },
    "@media (min-width:1536px) and (max-width:2559px)":{
      fontSize: "1.2rem",
    },
    "@media (min-width:2560px) and (max-width:3200px)":{
      fontSize: "1.8rem",
    }
        
    },

    '& .MuiTablePagination-selectIcon': {
    
      fontSize:"1rem",
      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: '1.1rem',
        
      },
  
      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.between('lg', 'xl')]: {
        fontSize: "1.3rem",
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: "1.6rem",
      },
      "@media (min-width:1536px) and (max-width:2559px)":{
        fontSize: "1.7rem",
      },
      "@media (min-width:2560px) and (max-width:3200px)":{
        fontSize: "1.8rem",
      }
      // "@media (min-width:768px)":{fontSize:'1.1rem'},
      // "@media (min-width:1024px)":{fontSize:'1.2rem'},
      // "@media (min-width:1366px)":{fontSize:'1.3rem'},
      // "@media (min-width:1920px)":{fontSize:'1.4rem'},
      // "@media (min-width:2560px)":{fontSize:'1.5rem'},
     
    },
    '& .MuiTablePagination-actions': {
      margin: theme.spacing(0), // Use theme.spacing for margin
      padding: theme.spacing(0),
      fontSize:"1rem",
    },

  
  }));
  