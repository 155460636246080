import React from 'react';
import { Typography, Box } from '@mui/material';
import { makeStyles } from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
  label: {
    color: '#0C87F0',
    flex: 1,
    fontSize: '0.9rem !important',
    '@media (min-width:2560px)': {
      fontSize: '1.6rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.8rem !important',
    },
  },
  value: {
    color: '#475569',
    flex: 2,
    fontSize: '1rem !important',
    '@media (min-width:2560px)': {
      fontSize: '1.6rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.8rem !important',
    },
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  featureList: {
    paddingLeft: theme.spacing(0),
    marginBottom: theme.spacing(2),
    listStyle: 'none',
    flex: 2,
  },
  featureItem: {
    marginBottom: theme.spacing(1),
    color: '#475569',
  },
  
   
  }));


interface DetailItemsProps {
  data: Record<string, string | string[]>;
}

const DetailItems: React.FC<DetailItemsProps> = ({ data }) => {
  const classes = useStyles();

  return (
    <>
      {Object?.keys(data)?.map((key) => (
        <Box key={key} className={classes.itemContainer}>
          <Typography fontWeight={600} className={classes.label}>{key}:</Typography>
          {Array.isArray(data[key]) ? (
            <ul className={classes.featureList}>
              {(data[key] as string[]).map((item, index) => (
                <li key={`details-${key}-${index}`} className={classes.featureItem}>{item}</li>
              ))}
            </ul>
          ) : (
            <Typography className={classes.value}>{data[key]}</Typography>
          )}
        </Box>
      ))}
    </>
  );
};

export default DetailItems;
