import React, { useState, useMemo } from 'react';
import {
  Box,
  Paper,
  Typography,
  Chip,
  Divider,
  IconButton,
  Modal,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core';
import CustomPagination from '../../../components/common/customPagination';
import { getSeverityColor, getStatusColor } from '../../utils/getColor';
import NoAttacksFound from '../../NoAttacksFound';
import viewDetailsLogo from '../../../assets/icons/svg/cyberwasp_veiw_details.svg'
import { ALL_COUNTRY_SHORT_CODES } from '../../../constants/countryCodes';
import { formatDateWithAMPM } from '../../utils/convertTime';

const StyledPaper = styled(Paper)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  paddingBottom: theme.spacing(2),
  borderRadius: theme.spacing(1),
  width: '80vw',
  maxWidth: '80vw',
  maxHeight: '90vh',
  overflowY: 'auto',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '&::-webkit-scrollbar': {
    width: '0rem',
  },
}));

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent:'space-evenly',
    marginTop:'1.2rem'
    
  },
  label: {
    backgroundColor: '#0C87F0',
    color: '#FFFFFF',
    padding: '0.48rem 3rem',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    border: '1px solid',
    borderColor: '#0046FF',
    borderRight: 'none',
    fontSize:'0.9rem !important',
     '@media (min-width:2560px)': {
      fontSize: '1.4rem !important',
      border: '2px solid #0046FF',

    },
    '@media (min-width:3840px)': {
      fontSize: '1.6rem !important',
    },
  },
  urlBox: {
    padding: '0.65rem 3rem',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    border: '1px solid #0046FF',
    borderLeft: 'none',
    color: '#0046FF',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize:'0.9rem !important',
    '@media (min-width:2560px)': {
      fontSize: '1.4rem !important',
      border: '2px solid #0046FF',

    },
    '@media (min-width:3840px)': {
      fontSize: '1.6rem !important',
    },

  },
  viewDetailsLogo:{
   marginRight:'2rem'
  },
  tableBodyCell: {
    fontSize:'0.8rem !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('lg')]: {
      maxWidth: 180,
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: 180,
    },
    '@media (min-width:2560px)': {
      fontSize: '1.5rem !important',
      textAlign:'center !important',

    },
    '@media (min-width:3840px)': {
      fontSize: '1.8rem !important',
    },
  },
  divider:{
    backgroundColor:'#0046FF !important',
    height:'0.1rem',

  },
  chip:{
     background: '#FF4B5529 !important',
     color: '#FF4B55 !important',ml: 1,
     fontSize:'0.9rem !important',
    '@media (min-width:2560px)': {
      fontSize: '1.2rem !important',
      padding:'1rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.4rem !important',
      padding:'1.1rem !important',
    },
  },
  ruleDetails:{
    lineHeight:'1rem !important',
    '@media (min-width:2560px)': {
      lineHeight:'1.8rem !important',

    },
    '@media (min-width:3840px)': {
      lineHeight:'2rem !important',
    },
  }

  
}));

const columnHeaders = ['Transaction ID', 'Source IP', 'Status', 'Event Time (IST)', 'Severity', 'Request Method'];

const HeaderRow = () => (
  <TableRow>
    {columnHeaders?.map((header) => (
      <TableCell align='center' sx={{whiteSpace:'wrap'}} key={header}>
        {header}
      </TableCell>
    ))}
  </TableRow>
);

const getFlagByCountryCode = (countryCode) => {
  const country = ALL_COUNTRY_SHORT_CODES.find(c => c.cca2 === countryCode);
  return country ? country?.flag : '';
};

const DataRow = ({ countryCode, row, classes }) => {
  console.log("row?.eventTime ",row?.eventTime,row)
  const flag = getFlagByCountryCode(countryCode);
  return (
    <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
      <TableCell className={classes.tableBodyCell} title={row?.transactionId}>{row?.transactionId}</TableCell>
      <TableCell className={classes.tableBodyCell} title={row?.clientIP} align='center'>
        {flag} {row?.clientIP}
      </TableCell>
      <TableCell align='center' style={{ color: getStatusColor(row?.status), maxWidth: 150, overflowWrap: 'break-word', backgroundColor: 'white' }}>{row?.status}</TableCell>
      <TableCell className={classes.tableBodyCell} title={row?.eventTime} align='center'>{row?.eventTime}</TableCell>
      <TableCell align='center'>
        <Chip label={row?.severity} style={{ backgroundColor: getSeverityColor(row?.severity), color: 'white' }} />
      </TableCell>
      <TableCell align='center'>{row?.requestMethod}</TableCell>
    </TableRow>
  );
};

export const TransactionTable = ({ countryCode,data }) => {
  const classes = useStyles();
  return (
    <TableContainer sx={{ mt: 2, borderRadius: '0.5rem', boxShadow: '0px 4px 20px 0px #4A556833' }}>
      <Table>
        <TableHead>
          <HeaderRow />
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <DataRow countryCode={countryCode} key={row?.transactionId} row={row} classes={classes} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const RuleDetails = ({rules,handlePageChange, rule, currentPage }) => {
  const classes = useStyles()
  const details = [
    { title: 'Attack Category', value: rule?.category },
    { title: 'Matched Data', value: rule?.matched_data },
    { title: 'Impact', value: rule?.additional?.impact },
    { title: 'Message', value: rule?.message },
    { title: 'Recommendation', value: rule?.additional?.recommendation }
  ];

  return (
    <Box sx={{ p: 2 ,boxShadow: '0px 4px 20px 0px #4A556833', borderRadius: '0.5rem' }}>
      <Typography color={'#0046FF'} fontWeight={550} variant="h2" component="h3" gutterBottom>
        Rule {currentPage}
      </Typography>
      <Divider className={classes.divider}/>

      {details?.map((detail, index) => (
        <Box sx={{pt:1.2,pb:0.5}} key={index}>
          <Typography
            className={classes.ruleDetails}
             color="#2C2D33" fontWeight={550} variant="h4" gutterBottom>
            {detail?.title}
          </Typography>
          <Typography 
            className={classes.ruleDetails}

             sx={{
              color:'#6E7079',
              wordWrap: "break-word",
              overflowWrap: 'break-word',
              whiteSpace: 'pre-line'
            }}
            variant="h4" gutterBottom>
            {detail?.value ? detail?.value:'Not Available'}
          </Typography>
        </Box>
      ))}
          <CustomPagination page={currentPage} totalPages={rules?.length} handleChangePage={handlePageChange} />
    </Box>

  );
};

const ViewMoreDetailsModal = ({ open, onClose, selectedRow }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const domain = selectedRow?.data?.domain_name;
  const uri = selectedRow?.data?.request_details?.uri;
  const queryString = selectedRow?.data?.request_details?.query_string;

  const url = `${domain}${uri}?${queryString}`;
  const transactionId = selectedRow?.data?.unique_id;
  const clientIP = selectedRow?.data?.client_details?.x_forwarded_for?.join(', ');
  const status = selectedRow?.data?.waf_status === 403 ? 'Blocked' : 'Allowed';
  // const eventTime = new Date(selectedRow?.data?.timestamp).toLocaleDateString()
  const eventTime = formatDateWithAMPM(selectedRow?.data?.timestamp)

  const severity = selectedRow?.data?.severity;
  const requestMethod = selectedRow?.data?.request_details?.request_method;
  const rules = selectedRow?.data?.rules;
  const countryCode = selectedRow?.data?.client_details?.geo_location_details?.country_code

  const handlePageChange = (event, value) => setCurrentPage(value);

  const currentRule = useMemo(() => rules?.[currentPage - 1] || rules?.[0], [rules, currentPage]);
  const data = useMemo(() => [{
    transactionId,
    clientIP,
    status,
    eventTime,
    severity,
    requestMethod,
  }], [transactionId, clientIP, status, eventTime, severity, requestMethod]);
 

  const classes = useStyles();

 
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="view-more-details-drawer"
      aria-describedby="view-more-details-drawer"
      data-testid="view-more-details-drawer"
    >
      <StyledPaper>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Box display="flex" pt={2} alignItems="center">
            <Typography color="#000000" variant="h2" component="h2">Request/Response Analysis</Typography>
            <Chip className={classes.chip} label="Blocked" size="small" />
          </Box>
         
          <IconButton data-testid="close-button" onClick={onClose} size="small">
            <CloseIcon sx={{color:'#0046FF'}} />
          </IconButton>
        </Box>
        <Divider className={classes.divider}/>

       { selectedRow ?
       <>
        <Box className={classes.container}>
          <img  className={classes.viewDetailsLogo} src={viewDetailsLogo} alt="viewDetailsLogo"/>
          <Typography className={classes.label}>URL:</Typography>
          <Box className={classes.urlBox}>{url}</Box>
        </Box>

        <TransactionTable countryCode={countryCode} data={data} />

        <Divider sx={{ my: 2 }} />

        <RuleDetails rules={rules} handlePageChange={handlePageChange} rule={currentRule} currentPage={currentPage} />

        </>:<NoAttacksFound data-testid="noVienMoreDetails" id={"noViewMoreDetails"}/>}
      </StyledPaper>
    </Modal>
  );
};

export default ViewMoreDetailsModal;
