const CONSTANTS = {
  WELCOME_MSG: "Cyber Wasp",
  COMPONENT_LIST_MSG: "component_list_msg",
  DEFAULT_MSG: "default_msg",
  PROFILE_MSG: "profile_msg",
  SETTINGS: "settings",
  PROFILE: "profile",
  LOGOUT: "logout",
  THEME: "theme",
  SXP_TITLE: "Welcome to TSF React base project",
  SXP_SUBTITLE: "Service eXperience Platform",
  SXP_DEFAULT_MSG: "Hi, Welcome To React Template Project",
  VERSION_ID: "WORKING",
  COULDNT_UPLOAD_DOC: "Could not upload Document",
  SUCCESS: "success",
  ERROR: "error",
  BUSINESS_USER: "business-user",
  SELECT_START_END_DATE: "Select start date and end date",
  USER_EMAIL: "user-email",
  WELCOME: "Welcome back",
  FIRST_NAME: "first-name",
  LAST_NAME: "last-name",
  USER_ID: "user-id",
  REACT_TOKEN: "react-token",
  USER_TYPE: "user-type",
  COUNTRY: "country",
  I18NEXTLNG: "i18nextLng",
  IS_WAF_ADMIN:"isWafAdmin",
  DOMAIN_OBJ:'domainObj',
  TIME_DURATION:'duration',
  EULA_ACCEPTANCE:'is_accepted_eula',
  ACCEPTED_EULA_VERSION:'ACCEPTED_EULA_VERSION',
  AWGMENT_USER_ID :'AWGMENT_USER_ID',
  KC_REALM_NAME :'KC_REALM_NAME',
  KC_AUTH_URL:'KC_AUTH_URL',
  KC_CLIENT_ID:'KC_CLIENT_ID',
  GATEWAY_URL:'GATEWAY_URL',
  AWGMENT_API:'AWGMENT_API',
  LOGO_URL:'LOGO_URL',
  EULA_DOC_ID:'EULA_DOC_ID',
  DMS_DOC_ID:'DMS_DOC_ID',
  PUBLIC_URL:"PUBLIC_URL",
  DOWNLOADED_LOGS_FILE_NAME:'cyberwasp_logs.pdf',
  IS_FROM_TO:'fromTo',
  NAVIGATE_TO_DASHBOARD:'/',
  CUSTOM_LABEL:'custom'
};

export default CONSTANTS;

export const phoneRegExp =/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const URL_PATTERN = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$|^(www\.)?[^/$?#]+\.[^\s]*$/i;

export const DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'Z'"