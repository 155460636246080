import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import DetailItems from '../../components/common/profileDetailsReusableComponent';
import { dateFormat } from '../utils/convertTime';

const useStyles = makeStyles((theme) => ({
  companyDetails: {
    width: '50%',
  },
}));

interface CompanyPageProps {
  id: string;
  data: {
    onboarding_date: string;
    address: string;
    gstin: string;
    pan: string;
  };
}

const CompanyPage: React.FC<CompanyPageProps> = ({ id, data }) => {
  const classes = useStyles();
  

  const companyData = {
    'Date on-boarded': dateFormat(data?.onboarding_date),
    'Address': data?.address,
    'GSTIN': data?.gstin,
    'PAN': data?.pan,
  };

  return (
    <Box id={id} className={classes.companyDetails}>
      <DetailItems data={companyData} />
    </Box>
  );
};

export default CompanyPage;
