import React from 'react';

import ReusableListComponent from './ReusableListComponent';


const AttacksByCategoryComponent = ({attacksByCategory}) => {

  return (
    <ReusableListComponent
      id="attackCategories"
      data={attacksByCategory}
      leftColumnHeader="Attack Category"
      rightColumnHeader="Count"
      filterLabel="Filter by Category"
      width="100%" title={"Attack Categories"}
      showLegendDot = {true}
      showSequence = {false}    
      legendDotColor={'#FB5607'}
      />

  )
    

};

export default AttacksByCategoryComponent;
