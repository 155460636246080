// useLogout.ts
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import CONSTANTS from "../../constants/constants";

export const useLogout = () => {
  const { keycloak } = useKeycloak();

  const navigate = useNavigate()

  const handleLogout = () => {
    navigate(`${CONSTANTS.NAVIGATE_TO_DASHBOARD}${process.env.REACT_APP_REALM}`)

    sessionStorage.clear();
    keycloak.logout();
  };

  return handleLogout;
};