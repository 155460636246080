import { debounce } from 'lodash';
import CONSTANTS from "../constants/constants";
import { 
    DOMAINS_BY_CLIENT_ENDPOINT, 
    getAttacksInformationEndpoint, 
    getAttacksTrednlineEndpoint, 
    getCombinedResponseEndpoint,
    getViewDetailsEndpoint 
} from "../constants/endpoints";
import { setDomain, setDomainsByClient } from "../redux/domainData/domainActions";
import { 
    setAttackInformationData, 
    setAttacksTrendlineData, 
    setCombinedResponse, 
} from "../redux/ui/uiActions";
import { convertIntoArray } from "../screen/utils/convertIntoArray";
import { request } from "./request";
import { initialStatsData } from "../components/initial/InitialStatsData";
import { setAttackTrendlineLoader, setAttacksInformationLoader, setCombinedResponseLoader} from '../redux/loader/loadingActions';
import dayjs from 'dayjs';
import {getDaysDifference } from '../screen/utils/convertTime';
import { initialUiState } from '../redux/ui/uiReducer';

export const domainsByClientApi = async(dispatch) => {
    try {
        const DOMAINS_BY_CLIENT_URL = `${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${DOMAINS_BY_CLIENT_ENDPOINT}`;
        const response: any = await request.get(DOMAINS_BY_CLIENT_URL);

        if (response?.success) {
            dispatch(setDomain(response?.data[0]));
            dispatch(setDomainsByClient(response?.data));
        } else {
            dispatch(setDomain({}));
            dispatch(setDomainsByClient([]));
        }
    } catch(error) {
        dispatch(setDomain({}));
        dispatch(setDomainsByClient([]))
    }
};


const severityLevels = ['High', 'Medium', 'Low'];


export const attackTrendLineChartApi = debounce(async(dispatch, domainId, startAndEndDateTime, timeline) => {
   
    dispatch(setAttackTrendlineLoader(true));
    const ATTACKS_TRENDLINE_ENDPOINT = getAttacksTrednlineEndpoint(domainId, startAndEndDateTime, timeline);

    try {
        const response: any = await request.get(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${ATTACKS_TRENDLINE_ENDPOINT}`);

        if (!response?.data || response?.data?.length === 0) {
            throw new Error("No data received from the API");
          }
      
        
        const diffDays = getDaysDifference(startAndEndDateTime.from, startAndEndDateTime.to);
                                            
        const lastDate = response?.data[response?.data.length - 1].end_time;
        if (!lastDate) throw new Error("Invalid lastDate in response data");

        const lastTime = dayjs(lastDate);
        const firstObjStartTime = response?.data[0].start_time;
        const firstObjEndTime = response?.data[0].end_time;
        if (!firstObjStartTime || !firstObjEndTime) throw new Error("Invalid start or end time in response data");

        const startTime = dayjs(firstObjStartTime);
        const endTime = dayjs(firstObjEndTime);

        const isNotSameHour =  startTime.hour() !== endTime.hour()
        const isNotSameDateOrMonth =  startTime.date() !== endTime.date() || startTime.month() !== endTime.month()

        const dataArray = response?.data??[]
        let nextTime;

        let updatedData = [...dataArray]


        const firstObject = {
            "total_count": 0,
            "attacks": 0,
            "legit": 0,
            "start_time":firstObjStartTime,
            "end_time": firstObjStartTime
  
        }

        const lastObject = {
            "total_count": 0,
            "attacks": 0,
            "legit": 0,
            "start_time":lastDate,
            "end_time": nextTime
            }


        // Determine the amount of time to add based on the timeline
        switch (timeline) {
        case '12h':
        case '24h':
            
            updatedData = [...dataArray,lastObject]

            if(isNotSameHour){
                updatedData.splice(0, 0, firstObject);
            }

            break;
        case 'custom':
            if(diffDays < 2){
                nextTime = lastTime.add(1, 'hour').format('YYYY-MM-DDTHH:mm:ssZ');

                const lastObject = {
                    "total_count": 0,
                    "attacks": 0,
                    "legit": 0,
                    "start_time":lastDate,
                    "end_time": nextTime
            
                }
                    updatedData = [firstObject,...dataArray,lastObject]
                
                }
            else if(isNotSameDateOrMonth){
                    updatedData = [firstObject,...dataArray]

                }else{
                    updatedData = [...dataArray]
                }
        break;

            
        case 'week':
        case 'year':
            if(isNotSameDateOrMonth){
                updatedData.splice(0, 0, firstObject);
            }
            break;
        default:
            updatedData = [...dataArray]
        }

        if (response?.success) {

            dispatch(setAttacksTrendlineData(updatedData));
        }

        } catch(error) {
            return
        } finally {
            dispatch(setAttackTrendlineLoader(false));
        }
}, 600);

export const attacksInformationApi = debounce(async(dispatch, page, domainId, startAndEndDateTime, searchTerm,countriesFilter,severitiesFilter) => {
    
    dispatch(setAttacksInformationLoader(true));
    const countryFilter = (countriesFilter?.length>0)?`&country_filter=${countriesFilter}`:''
    const severityFilter = (severitiesFilter?.length>0)?`&severity_filter=${severitiesFilter}`:''


    const ATTACKS_INFORMATION_ENDPOINT = getAttacksInformationEndpoint(domainId, startAndEndDateTime, page, searchTerm);

    try {
        const response: any = await request.get(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${ATTACKS_INFORMATION_ENDPOINT}${countryFilter}${severityFilter}`);

        if (response?.success) {
           dispatch(setAttackInformationData({
                data: response?.data?.attack_information_details,
                total: response?.data?.total
            }));
        }
    } catch(error) {
        console.log("error ", error);
    } finally {
        dispatch(setAttacksInformationLoader(false));
    }
}, 800);


export const viewMoreAttackInfoApi = async (domainId, uniqueId,startAndEndDate) => {
    const VIEW_ATTACK_DETAILS_ENDPOINT = getViewDetailsEndpoint(domainId, uniqueId,startAndEndDate);

    try {
        const response: any = await request.get(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${VIEW_ATTACK_DETAILS_ENDPOINT}`);

        if (response?.success) {
            return response;
        }

        return null;
    } catch(error) {
        console.log("error ", error);
        return null; // Explicitly return null in case of error

    }
};


export const combinedResponsesApi = debounce(async (dispatch, domainId, startAndEndDateTime) =>{
    

    dispatch(setCombinedResponseLoader(true))

    const COMBINED_API_ENDPOINT = getCombinedResponseEndpoint(domainId, startAndEndDateTime)

    try{

        dispatch(setCombinedResponse({
            ...initialUiState?.combinedResponse,
        }))

        const response: any = await request.get(`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${COMBINED_API_ENDPOINT}`);

        if(response?.success){  

            const updatedCardsData = initialStatsData?.map(stat => {
                const apiData = response?.data?.cards_data?.find(item => item[stat?.id] !== undefined);
                if (apiData) {
                    return {
                        ...stat,
                        value: apiData[stat?.id],
                        // growthRate: apiData?.growth_rate,
                        isLoading:false
                    };
                }
                return stat;
            });

        const updatedGeoMapData = {
            legit:response?.data?.geo_map_data?.legit,
            attack:response?.data?.geo_map_data?.attack,
        }

        const updatedSeverityData = {
            totalCount:response?.data?.severity_details.total_count,
            attacksBySeverity: severityLevels?.map((severity) => {
                const responseItem = response?.data?.severity_details?.severity_details?.find((resItem:any) => resItem?.name === severity);
                return responseItem || { value: 0, name: severity, percentage: 0 };
            })
        }


        let updatedAttackByCategory = []

        if (response?.data?.attacks_by_category_data !== null && response?.data?.attacks_by_category_data !== undefined 
        && Array.isArray(response?.data?.attacks_by_category_data)) {
            updatedAttackByCategory = response?.data?.attacks_by_category_data
        }

        const updatedRequestStats = {
            responseCodes: convertIntoArray(response?.data?.top_response_codes),
            owaspTop10Mapping: convertIntoArray(response?.data?.owasp_top_10),
            userAgents: convertIntoArray(response?.data?.top_user_agents),
            uriHits: convertIntoArray(response?.data?.top_uris),
            requestMethods: convertIntoArray(response?.data?.top_request_methods),
        }

        const combinedResponseData = {
            cardsData:updatedCardsData,
            geoMapData:updatedGeoMapData,
            severityDetails:updatedSeverityData,
            attacksByCategoryData:updatedAttackByCategory,
            requestStats:updatedRequestStats
        }


        dispatch(setCombinedResponse(combinedResponseData))

        }else{
            dispatch(setCombinedResponse({
                ...initialUiState?.combinedResponse,
                cardsData:initialStatsData?.map(stat=>{
                    return {
                        ...stat,
                        isLoading:false
                    }
                })
    
            }))
        }

    }
    catch(error){
        dispatch(setCombinedResponse({
            ...initialUiState?.combinedResponse,
            cardsData:initialStatsData?.map(stat=>{
                return {
                    ...stat,
                    isLoading:false
                }
            })

        }))
        console.log("error ", error);

    } finally {
        dispatch(setCombinedResponseLoader(false))
    }

},100)
