import React, { useState, useEffect } from 'react';
import EulaModal from './EulaModal';
import CONSTANTS from '../../constants/constants';
import { EULA_CHECK_ENDPOINT, EULA_SET_ENDPOINT } from '../../constants/endpoints';
import { request } from '../../services/request';
import { useLogout } from '../../screen/utils/logout';
import { useDispatch } from 'react-redux';
import { pushNotification } from '../../redux/notification/notificationActions';
import { useNavigate } from 'react-router-dom';
import NoDomainsOnboarded from '../../screen/NoDomainsOnboarded';
import "../../styles/EulaStyles.css"


interface EulaWrapperProps {
  children: (props: { showContent: boolean }) => React.ReactNode;
}

const EulaWrapper: React.FC<EulaWrapperProps> = ({ children }) => {
  const [showEula, setShowEula] = useState(false);
  const [isNoDomains, setIsNoDomains] = useState(false);
  const [showLoader,setShowLoader] = useState(true)

  const userId = sessionStorage.getItem(CONSTANTS.AWGMENT_USER_ID);

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const handleLogout = useLogout()


  useEffect(() => {
    const checkEulaStatus = async () => {

      try {
        
        // Check if the EULA status is already stored in local storage
        const storedEulaStatus = sessionStorage.getItem(CONSTANTS.EULA_ACCEPTANCE);
        
        if (storedEulaStatus === null) {
          // EULA status not stored, check with the API
          const eulaCheckApiUrl = `${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${EULA_CHECK_ENDPOINT}`
          const response: any = await request.get(eulaCheckApiUrl);
          if(response?.success){
            const isAcceptedEula = response?.data?.is_accepted_eula;
            if(isAcceptedEula){
              setShowEula(isAcceptedEula);
              setShowLoader(false)
              sessionStorage.setItem(CONSTANTS.EULA_ACCEPTANCE, (isAcceptedEula).toString());
            }
            setShowEula(!isAcceptedEula);
            setShowLoader(true)
            setTimeout(() => {
              setShowLoader(false);

            }, 0.0001);

            
          }
          else{
            setShowLoader(true)
            setTimeout(() => {
              setShowLoader(false);

            }, 0.0001);
            setIsNoDomains(!isNoDomains);
          }
     
        } else {
          // EULA status already stored, use the stored value

          if(JSON.parse(storedEulaStatus)){
            setShowLoader(false)

            setShowEula(!JSON.parse(storedEulaStatus));
          }else{
            setShowLoader(true)
            setTimeout(() => {
              setShowLoader(false);

            }, 0.0001);

            setShowEula(JSON.parse(storedEulaStatus));
          }


        }
      } catch (error) {
        // Optionally, you could log out the user here if this is a critical error
        handleLogout()
      }
      
    };

    if (sessionStorage.getItem(CONSTANTS.REACT_TOKEN)) {
      
      checkEulaStatus();

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAcceptEula = async () => {
    try {
      const EULA_ACCEPT_URL =`${sessionStorage.getItem(CONSTANTS.GATEWAY_URL)}${EULA_SET_ENDPOINT}${userId}`;

      const data = {
        is_accepted_eula: true,
      };
      const response: any = await request.put(EULA_ACCEPT_URL, data);

      if (response?.success) {
        setShowEula(false);
        // Store the EULA status in local storage
        sessionStorage.setItem(CONSTANTS.EULA_ACCEPTANCE, 'true');

        dispatch(
        pushNotification({
            isOpen: true,
            message: "Updated the EULA Acceptance successfully",
            type: CONSTANTS.SUCCESS,
        })
        );
    } 

    else if (response?.message?.includes('Mapping for user_id') 
      && response?.message?.includes('not found')){

        setShowEula(false);
        setIsNoDomains(!isNoDomains);
        navigate(`${CONSTANTS.NAVIGATE_TO_DASHBOARD}no-domains-onboarded`)

    } 
    
    else {
        dispatch(
        pushNotification({
            isOpen: true,
            message: "Failed to update EULA",
            type: CONSTANTS.ERROR,
        })
        );
    }


      
    } catch (error) {
      // Optionally, you could log out the user here if this is a critical error
    }
  };

  const handleRejectEula = useLogout()

  const userType = sessionStorage.getItem(CONSTANTS.USER_TYPE) === 'wafAdmin'


  const showContent = !showEula && !showLoader


  return (
   <div>
    {isNoDomains && !userType ?
      <NoDomainsOnboarded/>
    :<>
      {showLoader && (
        <div className="eula_loader-container">
          <div data-testid="eula-loader" className="eula_loader"></div>
        </div>
      )}
      {showEula && !showLoader &&(
        <EulaModal isOpen={showEula} onAccept={handleAcceptEula} onReject={handleRejectEula} />
      )}
      {children({ showContent})}


    </>}
    </div>
  );
};

export default EulaWrapper;