import { Autocomplete, TextField, Button, Box, InputAdornment } from "@mui/material"
import { useEffect, useState } from "react"
import { getAllPaginatedClients } from "./clientServices"

const AutoCompleteClients = ({ handleChangeClient, initialClientName = "" }) => {
    const [clientsList, setClientsList] = useState<any[]>([])
    const [selectedClient, setSelectedClient] = useState<any>(null)
    const [clientName, setClientName] = useState<string>(initialClientName)

    useEffect(() => {
        
    const handleGetAllClients = async () => {

        const response = await getAllPaginatedClients(1, 10, 'client_name', clientName)
        setClientsList(response?.data?.clients || [])
    }
    handleGetAllClients()

    }, [clientName])


    const handleAutocompleteChange = (event, newValue) => {
        setSelectedClient(newValue)
        setClientName(newValue ? newValue?.client_name : '')
    }

    const handleMapClient = () => {
        if (selectedClient) {
            handleChangeClient(selectedClient?.client_name,selectedClient?.client_id)
        }
    }

    return (
        <Box sx={{ width:"100%",display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Autocomplete
                value={selectedClient}
                onKeyUp={(e:any)=>{
                    setClientName(e.target.value)
                }}
                onChange={handleAutocompleteChange}
                options={clientsList}
                getOptionLabel={(option) => option?.client_name || ""}
                renderInput={(params) => (
                    <TextField
                        sx={{width:'96%'}}
                        {...params}
                        label="Select Client Name"
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            endAdornment: (
                                <InputAdornment position="end">
                                    {/* Hide clear icon */}
                                    <span style={{ display: 'none' }}>{params.InputProps.endAdornment}</span>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            />
            <Button 
                sx={{alignSelf:"flex-end",color:"#FFFFFF"}}
                variant="contained" 
                onClick={handleMapClient}
                disabled={!selectedClient}
            >
                Map Client
            </Button>
        </Box>
    )
}

export default AutoCompleteClients
