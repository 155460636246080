import React from "react";
import { INITIAL_THEME } from "./theme";
import Navigator from "./components/navigation/Navigator";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import RenderOnAuthenticated from "./components/RenderOnAuthenticated";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import CONSTANTS from "./constants/constants";
import { ThemeProvider } from "@mui/material";
import EulaWrapper from "./components/eula/EulaWrapper";
import Wrapper from "./components/layout/wrapper";
import Keycloak from "keycloak-js";
import GlobalStylesComponent from "./theme/GlobalStylesComponent";



const App = (props) => {
  
  const { config } = props; // Destructure props for easier access

  // Initialize Keycloak with provided configurations
    const keycloak:any = Keycloak({
        realm: `${config.realm}`,
        url: `${config.authURL}auth/`,
        clientId: `${config.clientId}`,
      });


  const setTokens = (): void => {
    
    const { token, refreshToken, idTokenParsed,tokenParsed} = keycloak;

    if (token && refreshToken && idTokenParsed) {


      const userType = tokenParsed?.clientRoles?.includes('waf-admin')?'wafAdmin':'user'
      
      sessionStorage.setItem(CONSTANTS.USER_EMAIL, idTokenParsed?.email);
      sessionStorage.setItem(CONSTANTS.FIRST_NAME, idTokenParsed?.given_name);
      sessionStorage.setItem(CONSTANTS.LAST_NAME, idTokenParsed?.family_name);
      sessionStorage.setItem(CONSTANTS.USER_ID, idTokenParsed?.sub);
      sessionStorage.setItem(CONSTANTS.REACT_TOKEN, token);
      sessionStorage.setItem(CONSTANTS.USER_TYPE, userType);
      sessionStorage.setItem(CONSTANTS.AWGMENT_USER_ID,tokenParsed?.userId );

      sessionStorage.setItem(CONSTANTS.KC_REALM_NAME, config.realm);
      sessionStorage.setItem(CONSTANTS.GATEWAY_URL, config.gatewayURL);
      sessionStorage.setItem(CONSTANTS.KC_AUTH_URL, config.authURL);
      sessionStorage.setItem(CONSTANTS.KC_CLIENT_ID, config.clientId);
      sessionStorage.setItem(CONSTANTS.AWGMENT_API, config.awgmentApi);
      sessionStorage.setItem(CONSTANTS.EULA_DOC_ID,config.eulaDocId);
      sessionStorage.setItem(CONSTANTS.DMS_DOC_ID, config.dmsDocId);

    }
  };
  
  const refreshAccessToken = (): void => {
    keycloak
      .updateToken(50)
      .success((refreshed: boolean) => {
        if (refreshed) {
          setTokens();
        }
      })
      .error(() => {
        sessionStorage.clear();
        keycloak.logout();
      });
  };

  const handleEvent = (event: string): void => {
    if (event === "onAuthSuccess") {
      if (window.location.href.indexOf("signup") > -1) {
        window.location.href = "/";
      }
      setTokens();
    }

    if (event === "onTokenExpired") {
      refreshAccessToken();
    }

    if (event === "onAuthLogout") {
      sessionStorage.clear();
    }
  };

  const getAppChildren = () => (
    <ThemeProvider theme={INITIAL_THEME}>
      <Provider store={store}>
      <GlobalStylesComponent/>

    <EulaWrapper>
      {({ showContent }) => (
            <Wrapper showContent={showContent}>
              <GlobalStylesComponent/>
              <Navigator />
            </Wrapper>
          )}
      </EulaWrapper> 
       

        
      </Provider>
    </ThemeProvider>
  );

  return (
    <div className="app-wrapper">
    
      <ReactKeycloakProvider
        initOptions={{
          onLoad: "login-required",
          checkLoginIframe: false,
        }}
        authClient={keycloak}
        onEvent={handleEvent}
      >
        <RenderOnAuthenticated>{getAppChildren()}</RenderOnAuthenticated>
      </ReactKeycloakProvider>
    </div>
  );
};

export default App;
